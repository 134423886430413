import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import './AmbassadorPage.css';

const AmbassadorPage = () => {
  const { user } = useContext(UserContext);
  const [discountCode, setDiscountCode] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [error, setError] = useState(null);
  const [earningsBreakdown, setEarningsBreakdown] = useState([]);
  const [discountError, setDiscountError] = useState(null);
  const [earningsError, setEarningsError] = useState(null);
  const [isLoadingDiscount, setIsLoadingDiscount] = useState(true);
  const [isLoadingEarnings, setIsLoadingEarnings] = useState(true);
  const [discountPercentage, setDiscountPercentage] = useState(null); // State for discount percentage

  useEffect(() => {
    if (user && user.phone) {
      setIsLoadingDiscount(true);
      fetch(
        `${process.env.REACT_APP_DISCOUNT_DETAILS_URL}?phone=${user.phone}` // Use URL from .env
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setDiscountError(data.error);
          } else {
            setDiscountCode(data.code);
            setCreatedAt(data.created_at);
            setDiscountPercentage(data.discountPercentage);
          }
        })
        .catch(() => setDiscountError("خطأ في جلب بيانات كود الخصم"))
        .finally(() => setIsLoadingDiscount(false));
    }
  }, [user]);
  
  useEffect(() => {
    if (discountCode) {
      setIsLoadingEarnings(true);
      fetch(
        `${process.env.REACT_APP_EARNINGS_BREAKDOWN_URL}?discount_code=${discountCode}` // Use URL from .env
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setEarningsError(data.error);
          } else {
            setEarningsBreakdown(data.logs);
          }
        })
        .catch(() => setEarningsError("خطأ في جلب بيانات الأرباح"))
        .finally(() => setIsLoadingEarnings(false));
    }
  }, [discountCode]);
  
  const referrals = earningsBreakdown.length;
  const responseDescriptions = {
    "Transaction declined": {
      description:
        "تم رفض العملية من قبل البنك المصدر لبطاقة العميل. يُرجى التحقق مع العميل للتأكد من صحة معلومات البطاقة أو اقتراح طريقة دفع بديلة. تواصل مع العميل لمساعدته على إعادة المحاولة أو استخدام بطاقة أخرى أو اختيار طريقة دفع مختلفة."
    },
    "Transaction has been cancelled by the Consumer.": {
      description:
        "تم إلغاء العملية من قبل العميل. يُرجى التواصل مع العميل لمعرفة سبب الإلغاء وتقديم المساعدة إذا كان هناك أي استفسار أو مشكلة تحتاج إلى حل."
    },
    "Insufficient funds": {
      description:
        "لا يوجد أموال كافية في حساب العميل لإتمام العملية. يُرجى توجيه العميل لتعبئة حسابه أو استخدام بطاقة أخرى. تواصل مع العميل لمساعدته على تعبئة رصيده أو اختيار وسيلة دفع بديلة."
    },
    "Transaction cannot be processed at this moment": {
      description:
        "تعذر إتمام العملية بسبب مشكلة تقنية مؤقتة. يُرجى إبلاغ العميل بإعادة المحاولة لاحقًا. تواصل مع العميل وأخبره أن يعيد المحاولة بعد فترة."
    },
    "Technical error": {
      description:
        "تعذر إتمام العملية بسبب مشكلة تقنية. يُرجى التواصل مع العميل للحصول على مزيد من التفاصيل وتقديم رقم العملية للتحقق. تأكد من رقم العملية وتواصل مع الدعم الفني إذا لزم الأمر."
    },
    "Request Dropped": {
      description:
        "تمت مقاطعة العملية بسبب مشكلة في اتصال العميل بالإنترنت أو إغلاق المتصفح قبل إتمام خطوة التحقق. تواصل مع العميل وأرشده لإعادة المحاولة دون إغلاق صفحة التحقق."
    },
    "Authentication failed": {
      description:
        "العميل أدخل رمز OTP غير صحيح أو لم يقم بإدخاله أثناء خطوة التحقق. تواصل مع العميل للتأكد من إدخال الرمز الصحيح كما تم استلامه."
    },
    "Failed to check fraud screen": {
      description:
        "تعذر إتمام العملية بسبب إدخال العميل بيانات غير صحيحة أو وجود مشكلة في أداة الكشف عن الاحتيال. تواصل مع العميل للتحقق من البيانات وتقديم رقم العملية للدعم إذا لزم الأمر."
    },
    "Transaction blocked by fraud check": {
      description:
        "تم حظر العملية بسبب قيود على الحساب (مثل الموقع الجغرافي أو السرعة). تواصل مع العميل للحصول على تفاصيل إضافية واقترح خيارات أخرى إذا لزم الأمر."
    },
    "Transaction waiting for customer’s action": {
      description:
        "العملية مؤقتة وسيتم تحديث حالتها تلقائيًا. يُرجى متابعة العميل للتحقق من اكتمال العملية. تأكد من التواصل مع العميل إذا لم يتم تحديث الحالة خلال ساعة."
    },
    "Transaction waiting for customer’s action (Pending)": {
      description:
        "العملية مؤقتة وسيتم تحديث حالتها تلقائيًا. يُرجى متابعة العميل للتحقق من اكتمال العملية. تأكد من التواصل مع العميل إذا لم يتم تحديث الحالة خلال ساعة."
    },
    "Uncertain": {
      description:
        "لم يتم تحديد حالة العملية النهائية بسبب مشكلة تقنية. تواصل مع العميل وانتظر حتى يتم تحديث الحالة أو تواصل مع الدعم إذا استمر الوضع غير واضح."
    },
    "3d secure check requested": {
      description:
        "لم يتم إتمام العملية لأن العميل لم يدخل رمز OTP أو انتهت المهلة. تواصل مع العميل للتأكد من إدخال الرمز الصحيح وإعادة المحاولة."
    },
    "Transaction challenged by Fraud": {
      description:
        "تم وضع العملية قيد التقييم بسبب الإبلاغ عنها كاحتيالية محتملة. تواصل مع العميل وانتظر تحديث الحالة، أو قدم تفاصيل إضافية حسب الحاجة."
    },
    "Success": {
      description:
        "تمت العملية بنجاح. تواصل مع العميل لتأكيد إتمام العملية وتقديم أي تفاصيل إضافية عند الحاجة."
    },
    "Refund Transaction Declined": {
      description:
        "تم رفض طلب الاسترداد لأسباب تتعلق بإعدادات البنك أو طول فترة الشراء. تواصل مع العميل لتوضيح الحالة واقتراح تقديم طلب استرداد جديد."
    }
  };

  return (
    <div className="ambassador-page">
      {/* Welcome Banner */}
      <header className="welcome-banner">
        <div className="banner-content">
          <h1 className="welcome-message">مرحبًا، {user ? user.name : 'سفير'}!</h1>
          <p className="welcome-message">رحلتك نحو الكسب تبدأ هنا. لنحقق ذلك!</p>
          {user && user.phone && (
            <p className="welcome-message">
              <strong>رقم الهاتف:</strong> {user.phone}
            </p>
          )}
        </div>
      </header>

      {/* Dashboard Overview */}
      <section className="dashboard-overview">
        <h2 className="section-title">نظرة عامة على لوحة التحكم</h2>
        <div className="dashboard-stats">
   


          <div className="stat-card">
            <h3>نسبة الخصم:</h3>
            <p className="stat-value">{discountPercentage}%</p>
          </div>
          <div className="stat-card">
            <h3>الإحالات</h3>
            <p className="stat-value">{referrals}</p>
          </div>
        </div>
      </section>

      {/* Discount Code Section */}
      <section className="discount-code-info">
        <h2 className="section-title">كود الخصم الخاص بك</h2>
        {isLoadingDiscount ? (
          <p className="loading-message">جارٍ التحميل...</p>
        ) : discountError ? (
          <p className="error-message">
            {discountError === "No discount details found"
              ? "لم يتم تعيين كود الخصم الخاص بك بعد"
              : `خطأ: ${discountError}`}
          </p>
        ) : (
          <div>
            <p className="discount-code">
              <strong>{discountCode}</strong>
            </p>
            {createdAt && (
              <p>
                <strong>تم إنشاؤه في:</strong> {new Date(createdAt).toLocaleString()}
              </p>
            )}
            <button
              className="copy-button"
              onClick={() => navigator.clipboard.writeText(discountCode)}
            >
              نسخ الكود
            </button>
          </div>
        )}

      </section>

      {/* Sharing Tools */}
      <section className="sharing-tools">
  <h2 className="section-title">مشاركة الكود الخاص بك</h2>
  <div className="sharing-buttons">
    <button
      className="share-button"
      onClick={() => {
        const tweetText = encodeURIComponent(
          `استخدم كود الخصم الخاص بي ${discountCode} للحصول على تخفيض رائع! قم بزيارة المنصة: https://lahalak.sa`
        );
        const twitterUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
        window.open(twitterUrl, "_blank");
      }}
    >
      مشاركة على تويتر
    </button>
    <button
      className="share-button"
      onClick={() => {
        const whatsappMessage = encodeURIComponent(
          `استخدم كود الخصم الخاص بي ${discountCode} للحصول على تخفيض رائع! قم بزيارة المنصة: https://lahalak.sa`
        );
        const whatsappUrl = `https://wa.me/?text=${whatsappMessage}`;
        window.open(whatsappUrl, "_blank");
      }}
    >
      مشاركة على واتساب
    </button>
    <button
      className="share-button"
      onClick={() => {
        const subject = encodeURIComponent("كود الخصم الخاص بي");
        const body = encodeURIComponent(
          `استخدم كود الخصم الخاص بي ${discountCode} للحصول على تخفيض رائع! قم بزيارة المنصة: https://lahalak.sa`
        );
        const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
        window.location.href = mailtoUrl;
      }}
    >
      إرسال الكود عبر البريد الإلكتروني
    </button>
  </div>
</section>







      <section className="earnings-breakdown">
        <h2 className="section-title">تفاصيل الأرباح</h2>
        <p className="note">
          تفاصيل الأرباح: يرجى الملاحظة أن إدخال الكود لا يعني بالضرورة إتمام عملية الشراء أو الدفع.
        </p>
        {isLoadingEarnings ? (
          <p className="loading-message">جارٍ التحميل...</p>
        ) : earningsError ? (
          <p className="warning-message">
            {earningsError === "No records found for the provided discount code."
              ? "لم يتم استعمال كود الخصم الخاص بك بعد."
              : `تنبيه: ${earningsError}`}
          </p>
        ) : earningsBreakdown.length > 0 ? (
          <div className="breakdown-cards">
          {earningsBreakdown.map((entry, index) => (
            <div className="breakdown-card" key={index}>
              <div className="card-content">
                <h3 className="card-title">تفاصيل المعاملة</h3>
                <p className="card-detail">
                  <strong>التاريخ:</strong> {new Date(entry.created_at).toLocaleString()}
                </p>
                <p className="card-detail">
                  <strong>العميل:</strong> {entry.user_name}
                </p>
                <p className="card-detail">
                  <strong>البريد الإلكتروني:</strong> {entry.user_email}
                </p>
                <p className="card-detail">
                  <strong>الهاتف:</strong> {entry.user_phone}
                </p>
                <p className="card-detail">
                  <strong>المرجع:</strong> {entry.reference_number}
                </p>
                <p className="card-detail">
                  <strong>المبلغ:</strong> {entry.amount} ريال
                </p>
                <p className="card-detail">
                  <strong>الرسالة:</strong>{" "}
                  {responseDescriptions[entry.response_message]?.description ||
                    entry.response_message}
                </p>
              </div>
            </div>
          ))}
        </div>
        
        ) : (
          <p className="loading-message">لا توجد بيانات لعرضها.</p>
        )}
      </section>





      <footer className="footer-support">
        <h2 className="footer-title">الدعم</h2>
        <div className="footer-content">
          <p>
            تحتاج إلى مساعدة؟ اتصل بنا أو تحقق من قسم الأسئلة الشائعة للحصول على إجابات سريعة.
          </p>
          <button className="footer-button-support">الاتصال بالدعم</button>
        </div>
      </footer>
    </div>
  );
};

export default AmbassadorPage;
