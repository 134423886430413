import './Navbar.css';
import React, { useState, useContext } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext'; // Import UserContext

const Navbar = () => {
  const { t } = useTranslation();
  const { authToken, setUser, setAuthToken, setUserRole, setIsApproved } = useContext(UserContext); // Access context
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  // Toggle Mobile Navigation
  const handleNav = () => setNav(!nav);

  // Close Mobile Navigation
  const closeNav = () => setNav(false);

  // Handle Logout Logic
  const handleLogout = () => {
    console.log('Logout clicked');
    setUser(null);
    setAuthToken(null);
    setUserRole('');
    setIsApproved(false);
    localStorage.clear(); // Clear localStorage
    closeNav();
    navigate('/'); // Redirect to login page
  };

  // Close Nav and Navigate
  const closeAndNavigate = (path) => {
    navigate(path);
    closeNav();
  };

  return (
    <div>
      {/* Desktop Navbar */}
      <nav className="navbar-container">
        <div className="navbar-logo">
          <Link to="/" onClick={closeNav}>
            Lahalak
          </Link>
        </div>

        <ul className="navbar-links hidden md:flex">
          {!authToken ? (
            <>
              <li>
                <Link to="/" onClick={closeNav}>
                  {t('Navbar.Login')}
                </Link>
              </li>
              <li>
                <Link to="/signup" onClick={closeNav}>
                  {t('Navbar.SignUp')}
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/dashboard" onClick={closeNav}>
                  {t('Navbar.Dashboard')}
                </Link>
              </li>
              <li>
                <button onClick={handleLogout} className="logout-button">
                  {t('Navbar.Logout')}
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>

      {/* Mobile Menu Toggle Button */}
      <div
        onClick={handleNav}
        className="mobile-toggle block md:hidden cursor-pointer"
      >
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Sidebar */}
      {nav && (
        <div className="overlay" onClick={closeNav}>
          <div
            className="fixed"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <button className="close-button" onClick={closeNav}>
              &times;
            </button>
            <ul>
              {!authToken ? (
                <>
                  <li>
                    <Link to="/" onClick={() => closeAndNavigate('/')}>
                      {t('Navbar.Login')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/signup" onClick={() => closeAndNavigate('/signup')}>
                      {t('Navbar.SignUp')}
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/dashboard" onClick={() => closeAndNavigate('/dashboard')}>
                      {t('Navbar.Dashboard')}
                    </Link>
                  </li>
                  <li>
                    <button className="logout-button" onClick={handleLogout}>
                      {t('Navbar.Logout')}
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
