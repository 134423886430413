import React, { useState, useEffect } from "react";
import "./TopManagementPage.css";

const API_URL = process.env.REACT_APP_API_URL;

const TopManagementPage = () => {
  const [members, setMembers] = useState([]); // List of members

  const [newMember, setNewMember] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    password: "",
  });

  const [editMember, setEditMember] = useState(null);

  const [loading, setLoading] = useState(false);

  const [activeSection, setActiveSection] = useState("members");
  const [discountDetails, setDiscountDetails] = useState([]); // New state for discount list
  const [usageLogs, setUsageLogs] = useState([]);
  const [jsonData, setJsonData] = useState([]);


  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [discountPercentage, setDiscountPercentage] = useState("");
  const [code, setCode] = useState("");
  useEffect(() => {
    fetchMembers();
    fetchUsageLogs();
  }, []);
  // const fetchUsageLogs = async () => {
  //   try {
  //     const response = await fetch(process.env.REACT_APP_USAGE_LOGS_URL, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
  //     }

  //     const contentType = response.headers.get("content-type");
  //     if (!contentType || !contentType.includes("application/json")) {
  //       const textResponse = await response.text();
  //       console.error("Invalid response format. Received:", textResponse);
  //       throw new Error("Expected JSON but received HTML or text.");
  //     }

  //     const log = await response.json();
  //     console.log("Fetched Usage Logs:", log.data);
  //   } catch (error) {
  //     console.error("Error fetching usage logs:", error.message);
  //   }

  // };


  const fetchUsageLogs = async () => {
    try {
      setLoading(true); // Indicate loading state
      console.log("Fetching usage logs from:", process.env.REACT_APP_USAGE_LOGS_URL);

      const response = await fetch(process.env.REACT_APP_USAGE_LOGS_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch usage logs. Status: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Fetched usage logs data:", data);

      // Accessing the usage logs from the nested data structure
      if (Array.isArray(data.data)) {
        setUsageLogs(data.data); // Set the fetched logs from data key

        // Convert to JSON and set to state
        const jsonString = JSON.stringify(data.data);
        setJsonData(jsonString); // Storing the JSON string in state
        console.log("JSON data:", jsonString); // Logging the JSON
      } else {
        console.warn("Unexpected response format for usage logs:", data);
        setUsageLogs([]); // Fallback to empty array if data is not as expected
      }
    } catch (error) {
      console.error("Error fetching usage logs:", error.message);
    } finally {
      setLoading(false); // Reset loading state
    }
  };













  const handleUploadData = async () => {
    try {
      // Validate required fields
      if (!userInfo.name || !userInfo.email || !userInfo.phone || !discountPercentage || !code) {
        alert("يرجى ملء جميع الحقول وتوليد رمز الخصم قبل التحميل.");
        return;
      }

      // Format the phone number
      let formattedPhone = userInfo.phone.trim();
      if (formattedPhone.startsWith("0")) {
        formattedPhone = "966" + formattedPhone.slice(1);
      }

      // Validate discount percentage
      const parsedDiscountPercentage = parseFloat(discountPercentage);
      if (isNaN(parsedDiscountPercentage) || parsedDiscountPercentage <= 0) {
        alert("يرجى إدخال نسبة خصم صالحة أكبر من 0.");
        return;
      }

      // Construct the data payload
      const data = {
        name: userInfo.name.trim(),
        email: userInfo.email.trim(),
        phone: formattedPhone,
        discountPercentage: parsedDiscountPercentage,
        code: code.trim(),
      };

      console.log("Data being uploaded:", data);

      // Send request to backend
      const response = await fetch(process.env.REACT_APP_DISCOUNT_HANDLER_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const rawResult = await response.text(); // Capture raw response
      console.log("Raw response:", rawResult);

      // Handle non-successful responses
      if (!response.ok) {
        if (response.status === 409) {
          // Handle phone number conflict
          const result = JSON.parse(rawResult);
          alert(result.error || "رقم الهاتف هذا لديه بالفعل رمز خصم مرتبط.");
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return;
      }

      // Parse and handle successful response
      const result = JSON.parse(rawResult);
      alert(result.message || "تم تحميل البيانات بنجاح!");
      console.log("Upload Result:", result);

      // Reset the form
      setUserInfo({ name: "", email: "", phone: "" });
      setDiscountPercentage("");
      setCode("");
    } catch (error) {
      console.error("Upload failed:", error.message);
      alert("فشل تحميل البيانات. يرجى المحاولة مرة أخرى لاحقًا.");
    }
  };
















  const fetchDiscountDetails = async () => {
    try {

      const response = await fetch(process.env.REACT_APP_DISPLAY_DISCOUNTS_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Server Error: ${response.status} - ${response.statusText}`);
      }

      const rawResponse = await response.text(); // Fetch raw response

      try {
        const result = JSON.parse(rawResponse); // Attempt to parse JSON
        if (result && result.data && Array.isArray(result.data)) {
          setDiscountDetails(result.data);
          console.log("Fetched Discount Details:", result.data);
        } else {
          console.warn("No valid discount data found in the response:", result);
          setDiscountDetails([]);
        }
      } catch (error) {
        console.error("Invalid JSON response:", rawResponse);
        alert("Unexpected server response. Please contact support.");
      }
    } catch (error) {
      console.error("Error fetching discount details:", error.message);
      alert("Failed to fetch discount details. Please try again later.");
    }
  };





  const handleDeleteDiscount = async (id) => {
    try {
      // Confirm the deletion
      const confirmed = window.confirm("Are you sure you want to delete this discount?");
      if (!confirmed) return;

      // Log the API endpoint

      // Send the DELETE request
      const response = await fetch(process.env.REACT_APP_DELETE_DISCOUNT_URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      const rawResponse = await response.text();
      console.log("Raw response:", rawResponse);

      try {
        const result = JSON.parse(rawResponse);

        if (response.ok) {
          alert("Discount deleted successfully!");
          setDiscountDetails((prev) => prev.filter((item) => item.id !== id));
        } else {
          alert(`Error: ${result.error || "Failed to delete the discount."}`);
        }
      } catch (error) {
        console.error("Invalid JSON response:", rawResponse);
        alert("Unexpected server response. Please contact support.");
      }
    } catch (error) {
      console.error("Error deleting discount:", error);
      alert("An error occurred. Please try again.");
    }
  };











  const handleGenerateCode = (discountPercentage, setGeneratedCode) => {
    if (!discountPercentage || isNaN(discountPercentage) || discountPercentage <= 0) {
      alert("Please enter a valid discount percentage greater than 0.");
      console.warn("Invalid discount percentage:", discountPercentage);
      return;
    }

    // Generate a random discount code
    const code = `${Math.random().toString(36).substring(2, 6).toUpperCase()}`;

    setGeneratedCode(`${code}`);
  };

  // Handler: Update User Info
  const handleUserInfoChange = (field, value, userInfo, setUserInfo) => {
    setUserInfo({ ...userInfo, [field]: value });
  };























  const updateMember = async () => {
    try {
      // Validate required fields
      if (!editMember.id || !editMember.name || !editMember.email || !editMember.phone || !editMember.role) {
        alert("All fields are required to update a member.");
        return;
      }

      // Log the member being updated
      console.log("Updating member with the following data:", editMember);

      // Construct the payload with all fields
      const payload = {
        id: editMember.id,
        name: editMember.name.trim(),
        email: editMember.email.trim(),
        phone: editMember.phone.trim(),
        role: editMember.role.trim(),
        password: editMember.password || null, // Optional field
        otp: editMember.otp || null, // Optional field
        otp_expiry: editMember.otp_expiry || null, // Optional field
        created_at: editMember.created_at || null, // Optional field
        updated_at: editMember.updated_at || new Date().toISOString(), // Set updated_at to current time if not provided
        is_verified: editMember.is_verified ? 1 : 0, // Convert boolean to integer
        approved: editMember.approved ? 1 : 0, // Convert boolean to integer
      };

      // Log the constructed payload before making the API call
      console.log("Payload to be sent to API:", payload);

      // API call to update the member
      const response = await fetch(`${API_URL}/update-member`, { // Add endpoint if required
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      // Check for successful response
      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Error response from server:", errorResponse);
        throw new Error(`Error: ${errorResponse.message || response.statusText}`);
      }

      // Parse the server response
      const result = await response.json();
      console.log("Response from server:", result);

      // Check if the update was successful
      if (result.status === "success") {
        alert("Member updated successfully!");
        fetchMembers(); // Refresh members list
        setEditMember(null); // Clear the editing state
      } else {
        console.warn("Update failed:", result.message || "Unknown error.");
        alert(result.message || "Failed to update the member. Please try again.");
      }
    } catch (error) {
      console.error("Error updating member:", error);
      alert("Failed to update the member. Please try again.");
    }
  };



  const deleteMember = async (id) => {
    try {
      const confirmed = window.confirm("Are you sure you want to delete this member?");
      if (confirmed) {
        const response = await fetch(API_URL, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        fetchMembers();
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    }
  };




  const fetchMembers = async () => {
    try {
      setLoading(true);

      console.log("Fetching members from:", API_URL);
      const response = await fetch(API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch members. Status: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Fetched members data:", data);

      if (Array.isArray(data)) {
        setMembers(data);
      } else {
        console.warn("Unexpected response format:", data);
        setMembers([]); // Set to empty array as fallback
      }
    } catch (error) {
      console.error("Error fetching members:", error.message);
      alert("Failed to fetch members. Please check your connection or backend status.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };


  const addMember = async () => {
    try {
      if (!newMember.name || !newMember.email || !newMember.phone || !newMember.role || !newMember.password) {
        alert("All fields are required to add a member.");
        return;
      }

      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newMember),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      fetchMembers();
      setNewMember({ name: "", email: "", phone: "", role: "", password: "" });
    } catch (error) {
      console.error("Error adding member:", error);
    }
  };

  return (
    <div className="top-management-container">
      {/* Navigation Bar */}
      <nav className="navbar">
        <button onClick={() => setActiveSection("members")}>Members</button>
        <button onClick={() => setActiveSection("reports")}>Reports</button>
        <button onClick={() => setActiveSection("Discounts_Setting")}>Discounts Setting</button>
      </nav>

      <h1>Top Management Dashboard</h1>

      {/* Conditional Rendering Based on activeSection */}
      {loading && <p>Loading...</p>}

      {activeSection === "members" && (
        <Member
          members={members}
          newMember={newMember}
          setNewMember={setNewMember}
          editMember={editMember}
          setEditMember={setEditMember}
          addMember={addMember}
          updateMember={updateMember}
          deleteMember={deleteMember}
        />
      )}

      {activeSection === "reports" && (
        <section className="reports-section">
          <h2>Reports</h2>
          <p>This section will display reports and analytics for top management.</p>
        </section>
      )}

      {activeSection === "Discounts_Setting" && (
        <Discounts_Setting
          fetchUsageLogs={fetchUsageLogs}
          usageLogs={usageLogs}
          jsonData={jsonData}
          fetchDiscountDetails={fetchDiscountDetails}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          discountPercentage={discountPercentage}
          setDiscountPercentage={setDiscountPercentage}
          code={code}
          setCode={setCode}
          handleGenerateCode={handleGenerateCode}
          handleUploadData={handleUploadData}
          discountDetails={discountDetails}
          handleDeleteDiscount={handleDeleteDiscount}

        />



      )}


    </div>
  );
};























function Member({
  members,
  newMember,
  setNewMember,
  editMember,
  setEditMember,
  addMember,
  updateMember,
  deleteMember,
  loading,
}) {
  { loading && <p>Loading members...</p> }

  return (
    <div>
      <section className="members-section">
        <h2 className="section-title">Members</h2>
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>OTP</th>
                <th>OTP Expiry</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Is Verified</th>
                <th>Approved</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {members.length > 0 ? (
                members.map((member) => (
                  <tr key={member.id}>
                    <td>{member.id}</td>
                    <td>{member.name}</td>
                    <td>{member.email}</td>
                    <td>{member.phone.startsWith('966') && member.phone.length === 12 ? '0' + member.phone.slice(3) : member.phone}</td>
                    <td>{member.role}</td>
                    <td>{member.otp}</td>
                    <td>{member.otp_expiry}</td>
                    <td>{new Date(member.created_at).toLocaleDateString()}</td>
                    <td>{new Date(member.updated_at).toLocaleDateString()}</td>
                    <td>{member.is_verified ? "Yes" : "No"}</td>
                    <td>{member.approved ? "Yes" : "No"}</td>
                    <td>
                      <button className="edit-button" onClick={() => setEditMember(member)}>
                        Edit
                      </button>
                      <button className="delete-button" onClick={() => deleteMember(member.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="no-data">No members found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>


      <section>
        <h2>Add Member</h2>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            value={newMember.name}
            onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
          />
          <input
            type="email"
            placeholder="Email"
            value={newMember.email}
            onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
          />
          <input
            type="text"
            placeholder="Phone"
            value={newMember.phone}
            onChange={(e) => setNewMember({ ...newMember, phone: e.target.value })}
          />
          <input
            type="text"
            placeholder="Role"
            value={newMember.role}
            onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
          />
          <input
            type="password"
            placeholder="Password"
            value={newMember.password}
            onChange={(e) => setNewMember({ ...newMember, password: e.target.value })}
          />
          <button onClick={addMember}>Add Member</button>
        </div>
      </section>

      {editMember && (
        <section>
          <h2>Edit Member</h2>
          <div className="form-group">
            <input
              type="text"
              placeholder="Name"
              value={editMember.name}
              onChange={(e) => setEditMember({ ...editMember, name: e.target.value })}
            />

            <input
              type="email"
              placeholder="Email"
              value={editMember.email}
              onChange={(e) => setEditMember({ ...editMember, email: e.target.value })}
            />

            <input
              type="text"
              placeholder="Phone"
              value={editMember.phone}
              onChange={(e) => setEditMember({ ...editMember, phone: e.target.value })}
            />

            <input
              type="password"
              placeholder="Password (optional)"
              value={editMember.password || ""}
              onChange={(e) => setEditMember({ ...editMember, password: e.target.value })}
            />

            {/* Role */}
            <label>
              Role:
              <select
                value={editMember.role}
                onChange={(e) => setEditMember({ ...editMember, role: e.target.value })}
              >
                <option value="Top Management">Top Management</option>
                <option value="Data Entry">Data Entry</option>
                <option value="Our Partners Services">Our Partners Services  </option>
                <option value="Finance">Finance</option>
                <option value="Customer Management">Customer Management</option>
                <option value="Ambassador">Ambassador</option>
                <option value="Provider Management">Provider Management</option>
              </select>
            </label>

            <input
              type="text"
              placeholder="OTP"
              value={editMember.otp || ""}
              onChange={(e) => setEditMember({ ...editMember, otp: e.target.value })}
            />

            <input
              type="datetime-local"
              placeholder="OTP Expiry"
              value={editMember.otp_expiry || ""}
              onChange={(e) => setEditMember({ ...editMember, otp_expiry: e.target.value })}
            />

            <input
              type="datetime-local"
              placeholder="Created At"
              value={editMember.created_at || ""}
              onChange={(e) => setEditMember({ ...editMember, created_at: e.target.value })}
              readOnly
            />

            <input
              type="datetime-local"
              placeholder="Updated At"
              value={editMember.updated_at || ""}
              onChange={(e) => setEditMember({ ...editMember, updated_at: e.target.value })}
            />

            {/* Is Verified */}
            <label>
              Is Verified:
              <select
                value={editMember.is_verified ? "true" : "false"}
                onChange={(e) =>
                  setEditMember({ ...editMember, is_verified: e.target.value === "true" })
                }
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </label>

            <label>
              Approved:
              <select
                value={editMember.approved ? "true" : "false"}
                onChange={(e) =>
                  setEditMember({ ...editMember, approved: e.target.value === "true" })
                }
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </label>

            {/* Buttons */}
            <div className="form-buttons">
              <button onClick={updateMember}>Update Member</button>
              <button onClick={() => setEditMember(null)}>Cancel</button>
            </div>
          </div>
        </section>
      )}

    </div>
  )
}








function Discounts_Setting({ jsonData,
  fetchUsageLogs,
  usageLogs,
  fetchDiscountDetails,
  userInfo,
  setUserInfo,
  discountPercentage,
  setDiscountPercentage,
  code,
  setCode,
  handleGenerateCode,
  handleUploadData,
  discountDetails, handleDeleteDiscount
}) {
  const calculateDiscount = (originalPrice, percentage) => {
    const discountAmount = (originalPrice * percentage) / 100;
    return {
      discountAmount,
      finalPrice: originalPrice - discountAmount,
    };
  };
  const [searchCode, setSearchCode] = useState("");
  const [filteredLogs, setFilteredLogs] = useState([]);
  const fetchDetailsByCode = () => {
    if (!searchCode.trim()) {
      alert("Please enter a discount code.");
      return;
    }
    const results = usageLogs.filter(
      (log) => log.discount_code.toLowerCase() === searchCode.toLowerCase()
    );
    setFilteredLogs(results);
  };

  const originalPrice = 100;
  const { discountAmount, finalPrice } = calculateDiscount(originalPrice, discountPercentage || 0);

  return (
    <div>
      <section className="settings-section">
        <h2>Generate Discount Code</h2>

        <div className="form-container">
          <div className="form-item">
            <label>
              Discount Percentage:
              <input
                type="number"
                step="0.01" // Allow decimal values
                placeholder="Enter Discount Percentage"
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(e.target.value)}
              />

            </label>
          </div>

          <div className="form-item">
            <label>
              Name:
              <input
                type="text"
                placeholder="Enter Name"
                value={userInfo.name}
                onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
              />
            </label>
          </div>

          <div className="form-item">
            <label>
              Email:
              <input
                type="email"
                placeholder="Enter Email"
                value={userInfo.email}
                onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
              />
            </label>
          </div>

          <div className="form-item">
            <label>
              Phone:
              <input
                type="text"
                placeholder="Enter Phone"
                value={userInfo.phone}
                onChange={(e) => setUserInfo({ ...userInfo, phone: e.target.value })}
              />
            </label>
          </div>
        </div>


        <div>
          <button onClick={() => handleGenerateCode(discountPercentage, setCode)}>
            Generate Discount Code
          </button>
        </div>

        {code && (
          <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ccc" }}>
            <h3>Generated Code</h3>
            <p><strong>{code}</strong></p>

            <div style={{ marginTop: "10px" }}>
              <h4>Example Calculation:</h4>
              <ul>
                <li>Original Price: {originalPrice}</li>
                <li>Discount Applied: {discountAmount.toFixed(2)}</li>
                <li>Final Price: {finalPrice.toFixed(2)}</li>
              </ul>
            </div>

            <div>
              <button onClick={handleUploadData} style={{ marginTop: "10px" }}>
                Add the Code
              </button>
            </div>
          </div>
        )}

        {/* Display Discount Details Table */}
        {discountDetails.length > 0 && (
          <div style={{ marginTop: "30px" }}>
            <h3>Uploaded Discounts</h3>
            <table border="1" style={{ width: "100%", marginTop: "10px" }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Discount %</th>
                  <th>Code</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {discountDetails.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone.startsWith("966") ? `0${item.phone.slice(3)}` : item.phone}</td>
                    <td>{item.discountPercentage}%</td>
                    <td>{item.code}</td>
                    <td>{new Date(item.created_at).toLocaleString()}</td>
                    <td>
                      <button onClick={() => handleDeleteDiscount(item.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        )}

        <div style={{ marginTop: "20px" }}>
          <button onClick={fetchDiscountDetails}>Refresh Discount List</button>
        </div>
      </section>
      <section className="settings-section" style={{ marginTop: "20px" }}>
        <h2>Usage Logs</h2>

        <div style={{ marginBottom: "20px" }}>
         

        </div>
        <div>
          <input
            id="discountCodeSearch"
            type="text"
            placeholder="Enter discount code"
            value={searchCode}
            onChange={(e) => setSearchCode(e.target.value)}
            style={{ marginLeft: "10px", padding: "5px", width: "200px" }}
          />
          <button
            onClick={fetchDetailsByCode}
            style={{ marginLeft: "10px", padding: "5px 10px" }}
          >
            Search
          </button>
          {filteredLogs.length > 0 ? (
            <div>
              <h3>Results for Code: {searchCode}</h3>
              <table border="1" style={{ width: "100%", marginTop: "10px" }}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Discount Code</th>
                    <th>User Name</th>
                    <th>User Email</th>
                    <th>User Phone</th>
                    <th>Reference Number</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredLogs.map((log) => (
                    <tr key={log.id}>
                      <td>{log.id || 'N/A'}</td>
                      <td>{log.discount_code || 'N/A'}</td>
                      <td>{log.user_name || 'N/A'}</td>
                      <td>{log.user_email || 'N/A'}</td>
                      <td>{log.user_phone || 'N/A'}</td>
                      <td>{log.reference_number || 'N/A'}</td>
                      <td>{log.created_at ? new Date(log.created_at).toLocaleString() : 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Optionally display the JSON string for filtered logs */}
            </div>
          ) : (
            searchCode && <p>No matching logs found for "{searchCode}".</p>
          )}
        </div>
        <div>
          {usageLogs.length > 0 ? (
            <>
              <table border="1" style={{ width: "100%", marginTop: "10px" }}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Discount Code</th>
                    <th>User Name</th>
                    <th>User Email</th>
                    <th>User Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {usageLogs.map((log) => {
                    const formattedPhone = log.user_phone.startsWith("966")
                      ? `0${log.user_phone.slice(3)}`
                      : log.user_phone;

                    return (
                      <tr key={log.id}>
                        <td>{log.id || 'N/A'}</td>
                        <td>{log.discount_code || 'N/A'}</td>
                        <td>{log.user_name || 'N/A'}</td>
                        <td>{log.user_email || 'N/A'}</td>
                        <td>{formattedPhone}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* Optionally display the JSON string */}
            </>
          ) : (
            <p>No usage logs available.</p> // Message if array is empty
          )}
        </div>
      </section>

    </div>
  );
}

export default TopManagementPage;






































