import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import './SignupPage.css';
import logo from '../assets/loba2.png';

function SignupPage() {
    const [role, setRole] = useState(''); // User role
    const [name, setName] = useState(''); // User name
    const [email, setEmail] = useState(''); // User email
    const [password, setPassword] = useState(''); // User password
    const [countryCode, setCountryCode] = useState('+966'); // Default country code (Saudi Arabia)
    const [phoneNumber, setPhoneNumber] = useState(''); // User phone number

    // OTP Handling States
    const [otp, setOtp] = useState(''); // OTP entered by the user
    const [isOtpSent, setIsOtpSent] = useState(false); // Indicates if OTP was sent
    const [generatedOtp, setGeneratedOtp] = useState(''); // Generated OTP for debugging
    const [otpError, setOtpError] = useState(''); // Error message for OTP
    const [otpSuccess, setOtpSuccess] = useState(false); // Indicates successful OTP verification

    // Error and Loading States
    const [formError, setFormError] = useState(''); // General form error
    const [loading, setLoading] = useState(false); // Indicates if a request is in progress

    const navigate = useNavigate(); // Navigation hook for page redirection

    // Handle role selection
    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    // Validate phone number format
    const validatePhoneNumber = (number) => {
        const formattedNumber = `${countryCode.replace('+', '')}${number.replace(/^0/, '')}`;
        return /^[0-9]{10,12}$/.test(formattedNumber); // Validate length
    };

    // Handle sign-up process
    const handleSignUp = async (e) => {
        e.preventDefault();
        setFormError(''); // Reset form error
        setLoading(true); // Show loading spinner
    
        // Check for empty fields
        if (!name || !email || !password || !phoneNumber) {
            setFormError('All fields are required.');
            setLoading(false);
            return;
        }
    
        // Validate password length
        if (password.length < 6) {
            setFormError('Password must be at least 6 characters long.');
            setLoading(false);
            return;
        }
    
        // Validate phone number format
        const formattedPhoneNumber = `${countryCode.replace('+', '')}${phoneNumber.replace(/^0/, '')}`;
        if (!validatePhoneNumber(phoneNumber)) {
            setFormError('Invalid phone number format.');
            setLoading(false);
            return;
        }
    
        const userData = {
            name,
            email,
            password,
            role: 'Ambassador', // Set role to "Ambassador"
            phone: formattedPhoneNumber,
        };
    
        console.log(userData);
    
        try {
            const response = await fetch(process.env.REACT_APP_SIGNUP_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData),
            });
    
            const data = await response.json();
    
            if (data.status === 'success') {
                alert('User registered successfully. Check your OTP for testing purposes.');
                setIsOtpSent(true); // Show the OTP input field
                setGeneratedOtp(data.otp); // Store the OTP from the server for display
            } else {
                setFormError(data.message || 'Failed to sign up. Please try again.');
            }
        } catch (error) {
            console.error('Error during sign-up:', error);
            setFormError('An error occurred while signing up.');
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };
    

    // Handle OTP verif
    // 
    // 
    // 
    
    
    
    
    
    const handleVerifyOtp = async () => {
        setOtpError(''); // Reset OTP error
    
        // Trim OTP and phone number inputs
        const trimmedOtp = otp.trim();
        const trimmedPhoneNumber = phoneNumber.trim();
    
        if (!trimmedOtp) {
            setOtpError('Please enter the OTP.');
            console.log("No OTP entered."); // Log if OTP is missing
            return;
        }
    
        // Format the phone number
        const formattedPhone = `${countryCode.replace('+', '')}${trimmedPhoneNumber.replace(/^0/, '')}`;
        console.log("Formatted phone number:", formattedPhone); // Log formatted phone number
        console.log("OTP entered by user:", trimmedOtp); // Log user-entered OTP
    
        try {
            setLoading(true); // Start loading spinner
    
            const response = await fetch('https://lahalak.sa/backend/AmbassadorB/auth/checked.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    phone: formattedPhone,
                    otp: trimmedOtp,
                }),
            });
    
            // Check for valid HTTP response
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // Attempt to parse JSON
            let data;
            try {
                data = await response.json();
            } catch (jsonError) {
                console.error("Invalid JSON response:", jsonError);
                console.error("Response text:", await response.text()); // Log response as text for debugging
                throw new Error('Unexpected server response. Please contact support.');
            }
    
            console.log("Response from backend:", data); // Log backend response
    
            if (data.status === 'success') {
                alert('OTP verified successfully!');
                navigate('/'); // Redirect to dashboard on success
            } else {
                console.log("Backend error message:", data.message); // Log error message
                setOtpError(data.message || 'Invalid OTP. Please try again.');
            }
        } catch (error) {
            console.error("Error during OTP verification:", error); // Log error object
            setOtpError(error.message || 'An error occurred. Please try again later.');
        } finally {
            setLoading(false); // Stop loading spinner
        }
    };
    














    return (
        <div className="signup-container">
            <div className="signup-box">
                <img src={logo} alt="Logo" className="signup-logo" />
                <h1>Sign Up</h1>
                <form onSubmit={handleSignUp}>
                    {/* Role Selection */}
                    <div className="role-select">
                        <label htmlFor="role">Select your role:</label>
                        {/* <select
                            id="role"
                            value={role}
                            onChange={handleRoleChange}
                            required
                        >
                            <option value="" disabled>
                                -- Select a Role --
                            </option>
                            <option value="Top Management">Top Management</option>
                            <option value="Data Entry">Data Entry</option>
                            <option value="Finance">Finance</option>
                            <option value="Customer Management">
                                Customer Management
                            </option>
                            <option value="Ambassador">سفير لهالك</option>
                        </select> */}
                        <select
                            id="role"
                            value="Ambassador" // Always set to "Ambassador"
                            disabled // Disables the select element to prevent changing
                            required
                        >
                            <option value="Ambassador">سفير لهالك</option>
                        </select>

                    </div>

                    {/* Name Input */}
                    <input
                        type="text"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    {/* Email Input */}
                    <input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    {/* Password Input */}
                    <input
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    {/* Phone Number Input */}
                    <div className="phone-input">
                        <select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            required
                        >
                            <option value="+966">+966 (Saudi Arabia)</option>
                        </select>
                        <input
                            type="tel"
                            placeholder="Phone Number"
                            required
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>

                    {/* OTP Verification */}
                    {isOtpSent && (
                        <>
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                required
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <button
                                type="button"
                                className="verify-otp-button"
                                onClick={handleVerifyOtp}
                            >
                                Verify OTP
                            </button>
                            {otpError && <p className="error-message">{otpError}</p>}
                        </>
                    )}

                    {/* Form Error */}
                    {formError && <p className="error-message">{formError}</p>}

                    {/* Sign Up Button */}
                    {!isOtpSent && (
                        <button type="submit" disabled={loading} className="sign-up-button">
                            {loading ? (
                                <span className="spinner"></span> // Spinner icon
                            ) : (
                                "Sign Up"
                            )}
                        </button>
                    )}
                </form>

                {/* OTP Success Message */}
                {otpSuccess && <p className="success-message">Sign-up complete! Redirecting...</p>}

                {/* Login Link */}
                <p className="login-link">
                    Already have an account? <a href="/">Login</a>
                </p>
            </div>
        </div>
    );

}

export default SignupPage;
