import React, { useEffect, useState } from 'react';
import './CustomerManagementPage.css';

function CustomerManagementPage() {
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [collapsedDates, setCollapsedDates] = useState({}); // Track collapsed state for each date
  const [transactionData, setTransactionData] = useState([]);
  const [activeTab, setActiveTab] = useState('customers'); // Default to 'customers'
  const [paymentAmount, setPaymentAmount] = useState(''); // For the amount input
  const [paymentLink, setPaymentLink] = useState(''); // For the generated payment link
  const [amount, setAmount] = useState(""); // State for the entered amount
  const [isGenerating, setIsGenerating] = useState(false); // State for the button loading state

  const toggleCollapse = (date) => {
    setCollapsedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };


  // Function to handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    console.log("Form submitted");

    // Validate the input amount
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert("Please enter a valid amount.");
      return;
    }

    setIsGenerating(true); // Set loading state

    try {
      const response = await fetch(
        process.env.REACT_APP_GENERATE_LINK_URL, // Use URL from .env
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount }),
        }
      );

      const result = await response.json();
      console.log("Response from :", result);

      if (result.success) {
        setPaymentLink(result.payment_link);
      } else {
        alert(result.error || "An error occurred while generating the payment link.");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsGenerating(false); // Reset loading state
    }
  };


  const copyLink = () => {
    navigator.clipboard
      .writeText(paymentLink)
      .then(() => {
        alert("Payment link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying link:", err);
        alert("Failed to copy the link.");
      });
  };









  const groupedOrders = orders.reduce((acc, order) => {
    const date = order.date.split(' ')[0];
    if (!acc[date]) acc[date] = [];
    acc[date].push(order);
    return acc;
  }, {});
  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_FETCH_TRANSACTIONS_URL, // Use URL from .env
          {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (Array.isArray(data)) {
          const sortedData = data.sort((a, b) => b.id - a.id); // Sort by ID (newest first)
          setTransactionData(sortedData); // Update the state with sorted data
        } else {
          throw new Error('Fetched data is not an array');
        }
      } catch (err) {
        console.error('Error fetching transaction data:', err);
        setError('Failed to fetch transaction data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, []);





  useEffect(() => {
    let isMounted = true;

    const fetchCustomerData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_CUSTOMER_DATA_URL);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (isMounted) {
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.order_date);
            const dateB = new Date(b.order_date);
            return dateB - dateA;
          });

          setCustomerData(sortedData);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Error fetching customer data:', err);
          setError('Failed to fetch customer data. Please try again later.');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchCustomerData();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_FETCH_ORDERS_URL); // Use URL from .env
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        const sortedOrders = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setOrders(sortedOrders);
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch orders. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);


  if (loading) {
    return <p>Loading customer data...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="customer-management-container">
      <h1 className="customer-management-title">Customer Management Dashboard</h1>
      <p className="customer-management-description">
        Welcome to the Customer Management section of the dashboard. Here, you can view and manage all aspects of customer data and interactions, ensuring a seamless customer experience.
      </p>

      {/* Navigation Bar */}
      <nav className="tab-nav">
        <ul className="tab-list">
          <li
            className={`tab-item ${activeTab === 'customers' ? 'active-tab' : ''}`}
            onClick={() => setActiveTab('customers')}
          >
            Customers
          </li>
          <li
            className={`tab-item ${activeTab === 'orders' ? 'active-tab' : ''}`}
            onClick={() => setActiveTab('orders')}
          >
            Orders
          </li>
          <li
            className={`tab-item ${activeTab === 'transactions' ? 'active-tab' : ''}`}
            onClick={() => setActiveTab('transactions')}
          >
            Transactions
          </li>
          <li
            className={`tab-item ${activeTab === 'UsersList' ? 'active-tab' : ''}`}
            onClick={() => setActiveTab('UsersList')}
          >
            Users
          </li>
          <li
            className={`tab-item ${activeTab === 'payment' ? 'active-tab' : ''}`}
            onClick={() => {
              console.log('Tab clicked: Payment');
              setActiveTab('payment');
            }}
          >
            Speed Payment Gateway
          </li>


        </ul>
      </nav>

      {/* Tab Content */}
      <div className="tab-content">
        {activeTab === 'customers' && <CPUP customerData={customerData} />}

        {activeTab === 'orders' && (
          <div>
            <h2>Orders</h2>
            {Object.entries(groupedOrders).map(([date, ordersForDate]) => (
              <div key={date}>
                <h3>{date}</h3>
                {ordersForDate.map((order) => (
                  <OrderDetails key={order.order_id} order={order} />
                ))}
              </div>
            ))}
          </div>
        )}

        {activeTab === 'transactions' && <CPBHSI transactionData={transactionData} />}
        {activeTab === 'UsersList' && <UsersList />}
        {activeTab === 'payment' && (
          <SpeedPayment
            amount={amount}
            setAmount={setAmount}
            isGenerating={isGenerating}
            handleFormSubmit={handleFormSubmit}
            paymentLink={paymentLink}
            copyLink={copyLink}
          />
        )}


      </div>
    </div>
  );


}

function CPUP({ customerData }) {
  return (
    <section className="customer-management-table">
      <h2>Customers Paid Using the Platform</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Service</th>
            <th>Amount</th>
            <th>Response Message</th>
            <th>Payment Option</th>
            <th>Date</th>
            <th>Reference Number</th>
          </tr>
        </thead>
        <tbody>
          {customerData
            .filter((customer) => customer.reference_number)
            .map((customer) => (
              <tr
                key={customer.order_id}
                className={
                  customer.payment_response_message === 'Success' ? 'success-row' : ''
                }
              >
                <td>{customer.order_id}</td>
                <td>{customer.customer_name}</td>
                <td>{customer.customer_email}</td>
                <td>{customer.order_service}</td>
                <td>{customer.payment_amount}</td>
                <td>{customer.payment_response_message}</td>
                <td>{customer.payment_option}</td>
                <td>{customer.order_date}</td>
                <td>
                  <button
                    className="reference-button"
                    onClick={() =>
                      window.open(
                        `https://lahalak.sa/Authorization_letter?referenceNumber=${customer.reference_number}`,
                        '_blank' // Opens in a new tab or window
                      )
                    }

                    disabled={!customer.reference_number}
                  >
                    View Letter
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  );
}

function OrderDetails({ order }) {
  return (
    <table className="order-details-table">
      <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td><strong>Date</strong></td>
          <td>{order.date}</td>
        </tr>
        <tr>
          <td><strong>Name</strong></td>
          <td>{order.name}</td>
        </tr>
        <tr>
          <td><strong>Email</strong></td>
          <td>{order.email}</td>
        </tr>
        <tr>
          <td><strong>Service</strong></td>
          <td>{order.service}</td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td>{order.total}</td>
        </tr>
        <tr>
          <td><strong>Phone</strong></td>
          <td>{order.phone}</td>
        </tr>
        <tr>
          <td><strong>Reference Number</strong></td>
          <td>{order.reference_number}</td>
        </tr>
        <tr>
          <td><strong>Nationality</strong></td>
          <td>{order.nationality}</td>
        </tr>
        <tr>
          <td><strong>Map URL</strong></td>
          <td>
            <a href={order.map_url} target="_blank" rel="noopener noreferrer">
              View Map
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}


function CPBHSI({ transactionData }) {

  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, message: "" });

  // Map to store descriptions and recommended actions for tooltips
  const responseDetails = {
    "Transaction has been cancelled by the Consumer.": {
      description: "تم إلغاء العملية من قبل العميل.",
      action: "يُرجى نصح العميل بالتأكد من تفاصيل العملية والمحاولة مرة أخرى إذا كان ذلك مطلوبًا."
    }

    ,
    "Order already processed": {
      description: "تمت معالجة الطلب بالفعل. يبدو أن العملية قد أُنجزت سابقًا، أو أن العميل حاول تنفيذ الطلب أكثر من مرة.",
      action: "يُرجى التحقق من حالة الطلب باستخدام رقم المرجع أو رقم الطلب. إذا كان الطلب قد أُنجز بنجاح، فأبلغ العميل بعدم الحاجة لإعادة المحاولة. إذا كان العميل في حيرة، فاحرص على فحص التفاصيل المرسلة بعناية بحثًا عن أي إشارات قد تُظهر سبب الالتباس."
    }

    ,
    "Transaction declined": {
      description: "تم رفض العملية من قبل البنك المصدر لبطاقة العميل.",
      action: "يُرجى نصح العميل بالمحاولة مرة أخرى، أو استخدام بطاقة أخرى، أو اختيار طريقة دفع أخرى.",
    },
    "Insufficient funds": {
      description: "لا يمتلك العميل أموالًا كافية في حسابه لإتمام العملية.",
      action: "يُرجى نصح العميل بإعادة تعبئة حسابه أو استخدام بطاقة أخرى أو اختيار طريقة دفع أخرى.",
    },
    "Transaction cannot be processed at this moment": {
      description: "تعذر إتمام العملية بسبب مشكلة تقنية مؤقتة.",
      action: "يُرجى نصح العميل بالمحاولة مرة أخرى لاحقًا.",
    },
    "Technical error": {
      description: "تعذر إتمام العملية بسبب مشكلة تقنية مؤقتة.",
      action: "يرجى التواصل مع merchantsupport-ps@amazon.com لإجراء مزيد من التحقيق. يرجى توفير FORT ID أو مرجع التاجر.",
    },
    "Request Dropped": {
      description: "تم مقاطعة معالجة العملية بسبب مشكلة في الاتصال بالإنترنت أو إغلاق المتصفح قبل إتمام خطوة الحماية ثلاثية الأبعاد.",
      action: "يُرجى نصح العميل بإعادة المحاولة دون إغلاق صفحة الحماية ثلاثية الأبعاد.",
    },
    "Authentication failed": {
      description: "أدخل العميل رمز OTP غير صحيح أو لم يقم بإدخال الرمز أثناء خطوة الحماية ثلاثية الأبعاد.",
      action: "يُرجى نصح العميل بإعادة المحاولة وإدخال رمز OTP الصحيح كما تم استلامه من البنك.",
    },
    "Failed to check fraud screen": {
      description: "العملية غير مكتملة بسبب إدخال بيانات بتنسيق غير صحيح أو تعطل أداة الكشف عن الاحتيال.",
      action: "يرجى التواصل مع merchantsupport-ps@amazon.com لإجراء مزيد من التحقيق. يرجى توفير FORT ID أو مرجع التاجر.",
    },
    "Transaction blocked by fraud check": {
      description: "تم حظر العملية بسبب قواعد الاحتيال المحددة على الحساب (مثل: النطاق الجغرافي، البلد، السرعة، وما إلى ذلك).",
      action: "يرجى التواصل مع فريق المخاطر risk-ps@amazon.com للحصول على توضيحات إضافية.",
    },
    "Transaction waiting for customer’s action": {
      description: "حالة مؤقتة، يتم استردادها تلقائيًا خلال 30 دقيقة إلى ساعة واحدة.",
      action: "يُرجى الانتظار حتى يتم تحديث الحالة النهائية على Fort. إذا لم يتم التحديث خلال ساعة، يرجى التواصل مع merchantsupport-ps@amazon.com لإجراء مزيد من التحقيق.",
    },
    "Transaction waiting for customer’s action (Pending)": {
      description: "حالة مؤقتة، يتم استردادها تلقائيًا خلال 30 دقيقة إلى ساعة واحدة.",
      action: "يُرجى الانتظار حتى يتم تحديث الحالة النهائية على Fort. إذا لم يتم التحديث خلال ساعة، يرجى التواصل مع merchantsupport-ps@amazon.com لإجراء مزيد من التحقيق.",
    },
    "Uncertain": {
      description: "لم يتم إرجاع الحالة النهائية للعملية بسبب مشكلة تقنية (مثل: انتهاء المهلة من طرف ثالث مثل البنك المصدر).",
      action: "يُرجى الانتظار حتى يتم تحديث الحالة النهائية على Fort. إذا لم يتم التحديث خلال ساعتين، يرجى التواصل مع merchantsupport-ps@amazon.com لإجراء مزيد من التحقيق.",
    },
    "3d secure check requested": {
      description: "لم تتم معالجة العملية لأنه لم يتم تسجيل رد على صفحة الحماية ثلاثية الأبعاد (مثل: عدم إدخال العميل رمز OTP أو انتهاء المهلة الزمنية للصفحة).",
      action: "يُرجى نصح العميل بإعادة المحاولة دون إغلاق صفحة OTP، والتأكد من إدخال الرمز الصحيح.",
    },
    "Transaction challenged by Fraud": {
      description: "تم وضع العملية قيد الانتظار حيث تم الإبلاغ عنها كعملية احتيالية محتملة، وتنتظر التقييم النهائي.",
      action: "يرجى التواصل مع فريق المخاطر risk-ps@amazon.com للحصول على توضيحات إضافية.",
    },
    "Success": {
      description: "تمت عملية الاسترداد بنجاح عبر بوابة Fort.",
      action: "يرجى إعلام العميل بأن الأمر قد يستغرق من 7 إلى 14 يوم عمل حتى يظهر المبلغ في حسابه. يرجى تقديم رمز التفويض ومرجع التسوية (RRN) المتوفر في تقرير العملية.",
    },
    "Refund Transaction Declined": {
      description: "تم رفض عملية الاسترداد بسبب (1) عدم تفعيل الاسترداد التلقائي من قبل البنك، (2) تجاوز تاريخ الشراء 12 شهرًا، أو (3) فشل الاسترداد في mada بسبب مشكلة معروفة في السوق.",
      action: "يرجى التواصل مع merchantsupport-ps@amazon.com لإجراء مزيد من التحقيق. بالنسبة لمعاملات mada، يرجى ملاحظة أن هذه مشكلة معروفة على مستوى السوق. يرجى تقديم مرجع العملية لإجراء طلب استرداد يدوي.",
    },
  };



  const handleMouseEnter = (e, responseMessage) => {
    if (responseDetails[responseMessage]) {
      const rect = e.target.getBoundingClientRect();
      setTooltip({
        visible: true,
        x: rect.right + 10,
        y: rect.top + window.scrollY,
        message: `
          Description: ${responseDetails[responseMessage].description}
          Action: ${responseDetails[responseMessage].action}
        `,
      });
    }
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, x: 0, y: 0, message: "" });
  };

  return (
    <section className="customer-management-table">
      <h2>العمليات التي قد يتم تقييمها للدفع</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Order Reference</th>
            <th>Customer Email</th>
            <th>Customer Number</th>
            <th>Timestamp</th>
            <th>Status</th>
            <th>Response Message</th>
            <th>Payment Option</th>
            <th>Reference Number</th>
            <th>رقم امازون</th>
          </tr>
        </thead>
        <tbody>
          {transactionData.map((transaction) => (

            <tr
              key={transaction.id}
              className={
                transaction.response_message === "Success"
                  ? "success-row"
                  : transaction.response_message === "Transaction blocked by fraud check"
                    ? "fraud-row"
                    : ""
              }
            >
              <td>{transaction.id}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.order_reference}</td>
              <td>
                {transaction.customer_email === "lahalak00@gmail.com"
                  ? "العمليه تتم عبر الرابط السريع"
                  : transaction.customer_email}
              </td>
              <td>{transaction.customer_number}</td>
              <td>
                {new Date(transaction.timestamp).toLocaleString("en-US", {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </td>
              <td>{transaction.status}</td>
              <td
                onMouseEnter={(e) => handleMouseEnter(e, transaction.response_message)}
                onMouseLeave={handleMouseLeave}
              >
                {transaction.response_message === "Transaction blocked by fraud check"
                  ? "تم إيقاف العملية للاشتباه بوجود محاولة احتيال"
                  : transaction.response_message}
              </td>
              <td>{transaction.payment_option}</td>
              <td>{transaction.reference_number}</td>
              <td>{transaction.fort_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {tooltip.visible && (
        <div
          style={{
            position: "absolute",
            top: tooltip.y,
            left: tooltip.x,
            padding: "8px 12px",
            backgroundColor: "black",
            color: "white",
            borderRadius: "5px",
            zIndex: 1000,
            maxWidth: "300px",
            fontSize: "14px",
            wordWrap: "break-word",
            overflow: "hidden",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            whiteSpace: "pre-wrap", // Ensure message wraps properly
          }}
        >
          {tooltip.message}
        </div>
      )}
    </section>
  );
}




function SpeedPayment({ amount, setAmount, isGenerating, handleFormSubmit, paymentLink, copyLink }) {
  return (
    <section className="customer-management-table">



      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <h1>Generate Payment Link</h1>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="amount">Enter Amount (SAR):</label>
          <br />
          <input
            type="number"
            step="0.01"
            id="amount"
            name="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)} // Update the amount state
            required
            style={{ marginTop: "10px", marginBottom: "10px", padding: "5px", width: "100%" }}
          />
          <br />
          <button
            type="submit"
            disabled={isGenerating}
            style={{
              padding: "10px 20px",
              backgroundColor: isGenerating ? "#ccc" : "#007BFF",
              color: "white",
              border: "none",
              cursor: isGenerating ? "not-allowed" : "pointer",
            }}
          >
            {isGenerating ? "Generating..." : "Generate Link"}
          </button>
        </form>
        <div id="paymentLinkContainer" style={{ marginTop: "20px" }}>
          {paymentLink && (
            <>
              <p>Generated Payment Link:</p>
              <input
                type="text"
                id="paymentLink"
                value={paymentLink}
                readOnly
                style={{ width: "100%", marginBottom: "10px", padding: "5px" }}
              />
              <button
                id="copyButton"
                onClick={copyLink}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#28a745",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Copy Link
              </button>
            </>
          )}
        </div>
      </div>

    </section>
  );





  function Userlogin({ customerData }) {
    return (
      <section className="customer-management-table">
        <h2>Customers Paid Using the Platform</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Service</th>
              <th>Amount</th>
              <th>Response Message</th>
              <th>Payment Option</th>
              <th>Date</th>
              <th>Reference Number</th>
            </tr>
          </thead>
          <tbody>
            {customerData
              .filter((customer) => customer.reference_number)
              .map((customer) => (
                <tr
                  key={customer.order_id}
                  className={
                    customer.payment_response_message === 'Success' ? 'success-row' : ''
                  }
                >
                  <td>{customer.order_id}</td>
                  <td>{customer.customer_name}</td>
                  <td>{customer.customer_email}</td>
                  <td>{customer.order_service}</td>
                  <td>{customer.payment_amount}</td>
                  <td>{customer.payment_response_message}</td>
                  <td>{customer.payment_option}</td>
                  <td>{customer.order_date}</td>
                  <td>
                    <button
                      className="reference-button"
                      onClick={() =>
                        window.open(
                          `https://lahalak.sa/Authorization_letter?referenceNumber=${customer.reference_number}`,
                          '_blank' // Opens in a new tab or window
                        )
                      }

                      disabled={!customer.reference_number}
                    >
                      View Letter
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </section>
    );
  }
}

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_USERS_URL, { // Use URL from .env
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (Array.isArray(data)) {
          setUsers(data); // Assuming the endpoint returns an array of user objects
        } else {
          throw new Error('Invalid response format: expected an array');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Users List</h1>
      <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td>{new Date(user.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerManagementPage;
