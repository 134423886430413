import React, { createContext, useState, useEffect,} from 'react';
 
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // User state
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    console.log('Initial User:', storedUser ? JSON.parse(storedUser) : null);
    return storedUser ? JSON.parse(storedUser) : null;
  });

  // Authentication token state
  const [authToken, setAuthToken] = useState(() => {
    const token = localStorage.getItem('authToken');
    console.log('Initial Auth Token:', token);
    return token || null;
  });

  const [userRole, setUserRole] = useState(() => {
    const role = localStorage.getItem('userRole');
    console.log('Initial User Role:', role);
    return role || '';
  });

  // Approval status state
  const [isApproved, setIsApproved] = useState(() => {
    const approved = localStorage.getItem('isApproved') === 'true';
    console.log('Initial Approval Status:', approved);
    return approved;
  });

  useEffect(() => {
    if (user) {
      console.log('Updating User in localStorage:', user);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      console.log('Removing User from localStorage');
      localStorage.removeItem('user');
    }
  }, [user]);

  useEffect(() => {
    if (authToken) {
      console.log('Updating Auth Token in localStorage:', authToken);
      localStorage.setItem('authToken', authToken);
    } else {
      console.log('Removing Auth Token from localStorage');
      localStorage.removeItem('authToken');
    }
  }, [authToken]);

  useEffect(() => {
    if (userRole) {
      console.log('Updating User Role in localStorage:', userRole);
      localStorage.setItem('userRole', userRole);
    } else {
      console.log('Removing User Role from localStorage');
      localStorage.removeItem('userRole');
    }
  }, [userRole]);

  // Save isApproved to localStorage whenever `isApproved` state changes
  useEffect(() => {
    console.log('Updating Approval Status in localStorage:', isApproved);
    localStorage.setItem('isApproved', isApproved);
  }, [isApproved]);

  // Logout function to clear all user data
  const logout = () => {
    console.log('Logging out and clearing all user data.');
    setUser(null);
    setAuthToken(null);
    setUserRole('');
    setIsApproved(false);
    localStorage.clear();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        authToken,
        setAuthToken,
        userRole,
        setUserRole,
        isApproved,
        setIsApproved,
        logout, // Provide the logout function in context
      }}
    >
      {children}
    </UserContext.Provider>
  );
};






























