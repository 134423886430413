import React, { useEffect, useState } from 'react';
import './DataEntry.css';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


const DataEntry = () => {
  const [activeTab, setActiveTab] = useState('hospitals');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="data-entry-container" style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>إدخال البيانات</h1>

      {/* أزرار التنقل بين التبويبات */}
      <div className="tabs" style={{ display: 'flex', marginBottom: '20px' }}>
        <button
          style={{
            flex: 1,
            padding: '10px',
            background: activeTab === 'hospitals' ? '#007bff' : '#f0f0f0', // تغيير اللون بناءً على التبويب النشط
            color: activeTab === 'hospitals' ? '#fff' : '#000', // تغيير لون النص
            border: 'none',
            cursor: 'pointer', // إظهار مؤشر عند التحويم
          }}
          onClick={() => handleTabChange('hospitals')} // تغيير التبويب النشط إلى "المستشفيات"
        >
          إدارة المستشفيات
        </button>
        <button
          style={{
            flex: 1,
            padding: '10px',
            background: activeTab === 'services' ? '#007bff' : '#f0f0f0', // تغيير اللون بناءً على التبويب النشط
            color: activeTab === 'services' ? '#fff' : '#000',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleTabChange('services')} // تغيير التبويب النشط إلى "الخدمات"
        >
          إدارة الخدمات
        </button>
        <button
          style={{
            flex: 1,
            padding: '10px',
            background: activeTab === 'seetheinfomation' ? '#007bff' : '#f0f0f0', // تغيير اللون بناءً على التبويب النشط
            color: activeTab === 'seetheinfomation' ? '#fff' : '#000',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleTabChange('seetheinfomation')} // تغيير التبويب النشط إلى "عرض المعلومات"
        >
          عرض المعلومات
        </button>
      </div>

      {/* عرض المكون المناسب بناءً على التبويب النشط */}
      {activeTab === 'hospitals' && <HospitalForm />}
      {activeTab === 'services' && <ServiceForm />}
      {activeTab === 'seetheinfomation' && <Seetheinfomation />}
    </div>
  );

};
const HospitalForm = () => {
  const [hospitalName, setHospitalName] = useState("");
  const [logo, setLogo] = useState("");
  const [location, setLocation] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [serviceIds, setServiceIds] = useState("");
  const [descriptions, setDescriptions] = useState([""]);
  const [options, setOptions] = useState([{ optionName: "", price: "" }]);

  const addDescriptionField = () => {
    setDescriptions([...descriptions, ""]);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = value;
    setDescriptions(updatedDescriptions);
  };

  // Add a new option field
  const addOptionField = () => {
    console.log("Adding a new option");
    setOptions((prevOptions) => [...prevOptions, { optionName: "", price: "" }]);
  };
  const handleOptionChange = (index, field, value) => {
    setOptions((prevOptions) =>
      prevOptions.map((option, i) =>
        i === index ? { ...option, [field]: value } : option
      )
    );
  };




  // معالجة إرسال النموذج
  const handleSubmit = async (event) => {
    event.preventDefault(); // منع التحديث الافتراضي للنموذج

    try {
      // تحضير البيانات للإرسال
      const data = {
        hospitalName, // اسم المستشفى
        logo, // الشعار
        location, // الموقع
        subtitle, // العنوان الفرعي
        serviceIds: serviceIds.split(",").map((id) => id.trim()), // تحويل معرفات الخدمات إلى مصفوفة وإزالة الفراغات
        bookingDetails: descriptions.filter((desc) => desc.trim() !== ""), // تصفية الأوصاف الفارغة أو التي تحتوي على مسافات فقط
        options: options.filter((option) => option.optionName && option.price), // التحقق من الخيارات الصحيحة (الاسم والسعر)
      };

      // تسجيل البيانات المرسلة لأغراض التصحيح
      console.log("إرسال البيانات التالية:", data);

      // التحقق من تعبئة الحقول المطلوبة
      if (!hospitalName || !location || !subtitle || !serviceIds.length || !logo) {
        alert("يرجى تعبئة جميع الحقول المطلوبة قبل الإرسال.");
        console.error("خطأ في التحقق: الحقول المطلوبة مفقودة.");
        return;
      }

      const ENDPOINT = process.env.REACT_APP_ADD_HOSPITAL_SERVICE_ENDPOINT;

      const response = await fetch(ENDPOINT, {
        method: "POST", 
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      

      const result = await response.json();

      // تسجيل استجابة الخادم لأغراض التصحيح
      console.log("استجابة الخادم:", result);

      if (response.ok && result.success) {
        alert("تمت إضافة معلومات المستشفى بنجاح!");
        console.log("تمت إضافة البيانات بنجاح:", data);
        // إعادة تعيين النموذج بعد الإرسال الناجح (اختياري)
        resetForm();
      } else {
        console.error("فشل في إضافة معلومات المستشفى. استجابة الخادم:", result);
        alert(result.message || "فشل في إضافة معلومات المستشفى.");
      }
    } catch (error) {
      console.error("خطأ أثناء إرسال النموذج:", error);
      alert("حدث خطأ. يرجى المحاولة مرة أخرى.");
    }
  };

  // إزالة خيار
  const removeOption = (index) => {
    console.log(`إزالة الخيار عند الفهرس ${index}`);
    setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index)); // إزالة الخيار بناءً على الفهرس
  };


  // Helper function to reset the form
  const resetForm = () => {
    setHospitalName("");
    setLogo("");
    setLocation("");
    setSubtitle("");
    setServiceIds("");
    setDescriptions([""]); // Reset descriptions
    setOptions([{ optionName: "", price: "" }]); // Reset options
  };

  return (
    <div className="hospital-form-container">
      <h1>إضافة مستشفى وخدمات</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="hospitalName">اسم المستشفى:</label>
        <input
          type="text"
          id="hospitalName"
          name="hospitalName"
          value={hospitalName}
          onChange={(e) => setHospitalName(e.target.value)}
          required
        />

        <label htmlFor="logo">Select Hospital Logo:</label>
        <select
          id="logo"
          name="logo"
          value={logo}
          onChange={(e) => {
            setLogo(e.target.value);
            console.log("Selected logo URL:", e.target.value); // Debugging log to verify selected value
          }}
          required
          style={{ padding: '10px', fontSize: '16px', width: '100%' }}
        >
          <option value="" disabled>
            اختر شعار المستشفى:
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Care4Y.png?alt=media&token=d67cb04f-6971-4975-b2c1-ecf15edc4dc9">
            Care For You - Jeddah
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713">
            Saudi German Hospital - University
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/andalusia.png?alt=media&token=3ad14da9-a5ef-41b2-81d7-aef6ca177081">
            Andalusia Hospital - Jeddah
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/passport_fc84a337-3840-4dd4-bacf-671c391447c6.png?alt=media&token=4ebfc2aa-3852-446b-9fee-743bc5c2b798">
            Dr. Irfan General Hospital - Jeddah
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713">
            Saudi German Hospital - Riyadh - Al Fayhaa
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Alia_Al_Farid.png?alt=media&token=52157d0e-bf5e-48d8-a91e-9516e9e3605e">
            Alia Al Farid Medical Complex
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/sakinia.png?alt=media&token=22935df8-dd34-4084-8df3-39a649b67671">
            Sakinah for Psychological and Family Counseling
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713">
            Saudi German Hospital - Mecca
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/MY_Clinic.png?alt=media&token=11d46c4c-ac51-4edc-ab56-bb177e1edc3e">
            My Clinic - Jeddah
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Aya.png?alt=media&token=980cd7f3-c37e-42ea-adfb-06b01fd30cc8">
            A Specialized Hospital
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/wid.png?alt=media&token=255e6a62-9956-40a9-9289-5169a2439c7e">
            Wid Home Nursing
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/midlab.png?alt=media&token=df20ea10-b449-4e16-ba93-307824043bf9">
            Midlab Laboratories
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Award_al_beshri_hospital.jpg?alt=media&token=42ac8117-9515-44bb-ae9f-54afb4065143">
            Dr. Awad Al Beshri Hospital
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/ACL.png?alt=media&token=00d3067d-dd4e-4ba9-a936-622228101960">
            Advanced Cell Laboratories
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/BADGHISH-logo-ar-1.png?alt=media&token=0da7928d-0429-4c3f-833d-ab20a4852038">
            Badghish Rehabilitation Center
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/healthLine.jpg?alt=media&token=7371e018-dba2-4ce9-b5ce-a66fda0482e6">
            Health Lines Center
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/masarat.png?alt=media&token=2ca1db8a-aeb0-44c6-b1c7-0f4b81a7d924">
            Masarat
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Pioneer.png?alt=media&token=e7fcf6b2-14b9-436c-93e4-cd2c5e9f11b9">
            Pioneer Care Medical Complex
          </option>
          <option value="https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/bader_homecare.png?alt=media&token=58f8e4a1-7937-42f8-85b0-177fdfab81b1">
            AL_bader Home Care
          </option>
        </select>


        <label htmlFor="location">الموقع:</label>
        <input
          type="text"
          id="location"
          name="location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />

        <label htmlFor="subtitle">العنوان الفرعي/الحي:</label>
        <input
          type="text"
          id="subtitle"
          name="subtitle"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
          required
        />

        <label htmlFor="serviceIds">Service IDs (comma separated):</label>
        <input
          type="text"
          id="serviceIds"
          name="serviceIds"
          value={serviceIds}
          onChange={(e) => setServiceIds(e.target.value)}
          placeholder="e.g., 19, 20, 21"
          required
        />

        <h3>تفاصيل الحجز</h3>
        <div id="descriptionsContainer">
          {descriptions.map((description, index) => (
            <input
              key={index}
              type="text"
              name={`description${index + 1}`}
              placeholder={`Description ${index + 1}`}
              value={description}
              onChange={(e) => handleDescriptionChange(index, e.target.value)}
              required
            />
          ))}
        </div>
        <button type="button" className="hospital-form-add-btn" onClick={addDescriptionField}>
          إضافة وصف آخر





        </button>

        <h3>الخيارات</h3>





        <div id="optionsContainer">
          {options.length > 0 ? (
            options.map((option, index) => (
              <div className="hospital-form-input-group" key={index}>
                <label htmlFor={`optionName${index}`}>Option {index + 1} Name:</label>
                <input
                  type="text"
                  id={`optionName${index}`}
                  name={`optionName${index}`}
                  placeholder={`Option Name ${index + 1}`}
                  value={option.optionName || ""}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    console.log(`Updating optionName for Option ${index + 1}:`, newValue);

                    // Update the value but preserve middle spaces and defer trimming end spaces
                    handleOptionChange(index, "optionName", newValue);
                  }}
                  onBlur={(e) => {
                    // Optionally trim trailing spaces only on blur
                    const trimmedValue = e.target.value.trimEnd(); // Removes spaces only at the end
                    handleOptionChange(index, "optionName", trimmedValue);
                  }}
                  required
                />



                <label htmlFor={`price${index}`}>Price {index + 1}:</label>
                <input
                  type="text"
                  id={`price${index}`}
                  name={`price${index}`}
                  placeholder={`Price ${index + 1}`}
                  value={option.price || ""} // Ensure the correct key is used
                  onChange={(e) => {
                    console.log(`Updating price for Option ${index + 1}:`, e.target.value);
                    handleOptionChange(index, "price", e.target.value.trim()); // Update price
                  }}
                  required
                />

                <button
                  type="button"
                  onClick={() => {
                    console.log(`Removing option at index ${index}`);
                    removeOption(index); // Call a function to remove the option
                  }}
                  style={{
                    marginTop: "10px",
                    padding: "5px 10px",
                    backgroundColor: "#FF4C4C",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  إزالة الخيار





                </button>
              </div>
            ))
          ) : (
            <p style={{ textAlign: "center", color: "gray" }}>
              لا توجد خيارات متاحة. أضف خيارًا جديدًا أدناه.






            </p>
          )}

        </div>
        <button type="button" className="add-btn" onClick={addOptionField}>
          إضافة خيار آخر





        </button>

        <input type="submit" value="Add Hospital" />
      </form>
    </div>
  );
};
const ServiceForm = () => {
  const [sections, setSections] = useState([{ title: '', content: '' }]);
  const [formData, setFormData] = useState({
    serviceId: '',
    title: '',
    summary: '',
    description: '',
    imageFile: null,
    imageUrl: '',
    category: '',
    includedServices: '',
    specialConditions: '',
    footerCTA: {
      title: '',
      description: '',
      buttonText: '',
    },
  });
  const [specialiste1, setSpecialiste1] = useState([]);
  const [specialiste2, setSpecialiste2] = useState([]);
  const handleSpecialisteChange = (type, value, checked) => {
    if (type === 'specialiste1') {
      setSpecialiste1((prev) =>
        checked ? [...prev, value] : prev.filter((item) => item !== value)
      );
    } else if (type === 'specialiste2') {
      setSpecialiste2((prev) =>
        checked ? [...prev, value] : prev.filter((item) => item !== value)
      );
    }
  };

  const handleUpload = async () => {
    try {
      const specialtyPayload = {
        service_id: formData.serviceId || '',
        specialiste1: specialiste1.join(', '),
        specialiste2: specialiste2.map((specialist) => ({ title: specialist })),
      };

      console.log('Uploading Payload:', specialtyPayload);
      if (!specialtyPayload.service_id) {
        alert('Service ID is missing.');
        return;
      }

      if (!specialiste1.length && !specialiste2.length) {
        alert('Specialties are missing.');
        return;
      }

      const ENDPOINT = process.env.REACT_APP_SPECIALTY_FORM_ENDPOINT;

      const response = await fetch(ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(specialtyPayload),
      });
      

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Upload Error:', errorText);
        alert('Failed to upload the specialties. Please check the logs.');
        return;
      }

      const responseData = await response.json();
      console.log('Upload Success:', responseData);

      if (responseData.success) {
        alert('Specialties uploaded successfully!');
      } else {
        alert(`Failed to upload specialties: ${responseData.message}`);
      }
    } catch (error) {
      console.error('Upload Error:', error);
      alert('An error occurred while uploading specialties.');
    }
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleFooterChange = (field, value) => {
    setFormData({
      ...formData,
      footerCTA: { ...formData.footerCTA, [field]: value },
    });
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index][field] = value;
    setSections(updatedSections);
  };

  const addSection = () => {
    setSections([...sections, { title: '', content: '' }]);
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, imageFile: e.target.files[0] });
  };

  const uploadImageToFirebase = async () => {
    if (!formData.imageFile) return null;

    const firebaseStorageUrl = `https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/`;
    const imageName = encodeURIComponent(formData.imageFile.name);

    try {
      const response = await fetch(`${firebaseStorageUrl}${imageName}?uploadType=media`, {
        method: 'POST',
        headers: {
          'Content-Type': formData.imageFile.type,
          Authorization: `Bearer YOUR_FIREBASE_API_KEY`,
        },
        body: formData.imageFile,
      });

      if (!response.ok) throw new Error('Failed to upload image to Firebase');

      const data = await response.json();
      const imageUrl = `${firebaseStorageUrl}${imageName}?alt=media&token=${data.downloadTokens}`;
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // منع التحديث الافتراضي للنموذج

    try {
      // رفع الصورة والحصول على الرابط
      const imageUrl = await uploadImageToFirebase();

      // إعداد البيانات الخاصة بالخدمة
      const servicePayload = {
        id: formData.serviceId, // معرف الخدمة
        title: formData.title, // عنوان الخدمة
        summary: formData.summary, // ملخص الخدمة
        description: formData.description, // وصف الخدمة
        image: imageUrl || '', // رابط الصورة (إذا كان موجوداً)
        category: formData.category, // تصنيف الخدمة
        sections: sections.map((section) => ({
          title: section.title, // عنوان القسم
          content: section.content.split('\n'), // تقسيم المحتوى إلى أسطر
        })),
        included_services_content: formData.includedServices, // الخدمات المشمولة
        special_conditions_content: formData.specialConditions, // الشروط الخاصة
        footer_cta_title: formData.footerCTA.title, // عنوان الحث على الإجراء
        footer_cta_description: formData.footerCTA.description, // وصف الحث على الإجراء
        footer_button_text: formData.footerCTA.buttonText, // نص زر الحث على الإجراء
      };

      console.log('البيانات المرسلة للخدمة:', servicePayload);

      // إعداد البيانات الخاصة بالمرشحات
      const filtersPayload = {
        service_id: formData.serviceId, // معرف الخدمة
        filters: {
          gender: filters.gender || [], // الفئة الجنسية
          age_group: filters.age_group || [], // الفئة العمرية
          treatment_stage: filters.treatment_stage || [], // مرحلة العلاج
          medical_procedure: filters.medical_procedure || [], // الإجراء الطبي
          service_duration: filters.service_duration || [], // مدة الخدمة
          cost_type: filters.cost_type || [], // نوع التكلفة
          service_location: filters.service_location || [], // موقع الخدمة
          procedure_type: filters.procedure_type || [], // نوع الإجراء
        },
      };

      console.log('البيانات المرسلة للمرشحات:', filtersPayload);

      const ENDPOINT = process.env.REACT_APP_ADDITIONAL_SERVICES_ENDPOINT;
      const serviceResponse = await fetch(ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(servicePayload),
      });

      if (!serviceResponse.ok) {
        const errorText = await serviceResponse.text();
        console.error('خطأ في نقطة نهاية الخدمة:', errorText);
        alert('فشل في إرسال الخدمة. يرجى مراجعة السجلات.');
        return;
      }

      const serviceData = await serviceResponse.json();
      console.log('نجاح إرسال الخدمة:', serviceData);

      // إرسال البيانات إلى نقطة النهاية الخاصة بالمرشحات
      const FILTERS_API_URL = process.env.REACT_APP_FILTERS_ENDPOINT;

      const filtersResponse = await fetch(FILTERS_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(filtersPayload),
      });
      

      if (!filtersResponse.ok) {
        const errorText = await filtersResponse.text();
        console.error('خطأ في نقطة نهاية المرشحات:', errorText);
        alert('فشل في إرسال المرشحات. يرجى مراجعة السجلات.');
        return;
      }

      const filtersData = await filtersResponse.json();
      console.log('نجاح إرسال المرشحات:', filtersData);

      // التحقق من نجاح العمليات
      if (serviceData.success && filtersData.success) {
        alert('تمت إضافة الخدمة والمرشحات بنجاح!');
      } else {
        alert('بعض الإرسالات لم تنجح. يرجى مراجعة السجلات.');
      }
    } catch (error) {
      console.error('خطأ أثناء الإرسال:', error);
      alert('فشل في إرسال الخدمة. يرجى التحقق من الشبكة والمحاولة مرة أخرى.');
    }
  };






  const removeSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  const resetForm = () => {
    setFormData({
      serviceId: '',
      title: '',
      summary: '',
      description: '',
      imageFile: null,
      category: '',
      includedServices: '',
      specialConditions: '',
      footerCTA: {
        title: '',
        description: '',
        buttonText: '',
      },
    });
    setSections([{ title: '', content: '' }]); // Reset sections to one empty section
  };
  const [filters, setFilters] = useState({
    gender: [],
    age_group: [],
    treatment_stage: [],
    medical_procedure: [],
    service_duration: [],
    cost_type: [],
    service_location: [],
    procedure_type: [],
  });

  const handleCheckboxChange = (filterCategory, value, isChecked) => {
    setFilters((prevFilters) => {
      const updatedCategory = isChecked
        ? [...prevFilters[filterCategory], value] // Add the value if checked
        : prevFilters[filterCategory].filter((item) => item !== value); // Remove the value if unchecked

      return {
        ...prevFilters,
        [filterCategory]: updatedCategory, // Update the specific filter category
      };
    });
  };


  return (
    <div className="service-form" style={{ padding: '20px', maxWidth: '700px', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center' }}>Add a New Service</h2>
      <form onSubmit={handleFormSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <fieldset style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px' }}>
          <legend style={{ fontWeight: 'bold' }}>تفاصيل الخدمة</legend>

          <label htmlFor="serviceId">معرف الخدمة:</label>
          <input
            type="text"
            id="serviceId"
            value={formData.serviceId}
            onChange={(e) => handleInputChange('serviceId', e.target.value)}
            placeholder="أدخل معرف فريد للخدمة"
            required
          />

          <label htmlFor="title">العنوان:</label>
          <input
            type="text"
            id="title"
            value={formData.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
            placeholder="أدخل عنوان الخدمة"
            required
          />

          <label htmlFor="summary">الملخص:</label>
          <textarea
            id="summary"
            value={formData.summary}
            onChange={(e) => handleInputChange('summary', e.target.value)}
            placeholder="قدم ملخصاً مختصراً للخدمة"
            rows="3"
            required
            style={{
              padding: '10px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              resize: 'vertical', // يسمح بتغيير الحجم عمودياً
              boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)', // إضافة ظل خفيف
              width: '700px', // تحديد العرض
              height: '30px', // تحديد الارتفاع
              direction: 'rtl', // تعيين اتجاه النص من اليمين إلى اليسار
            }}
          ></textarea>

          <label htmlFor="description">الوصف:</label>
          <textarea
            id="description"
            value={formData.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            placeholder="أدخل وصفاً مفصلاً للخدمة"
            rows="4"
            required
            style={{
              padding: '10px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              resize: 'vertical', // يسمح بتغيير الحجم عمودياً
              boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)', // إضافة ظل خفيف
              width: '700px', // تحديد العرض
              height: '30px', // تحديد الارتفاع
              direction: 'rtl', // تعيين اتجاه النص من اليمين إلى اليسار
            }}
          ></textarea>
        </fieldset>


        <fieldset style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px' }}>
          <legend style={{ fontWeight: 'bold' }}>رفع الصورة</legend>
          <label htmlFor="imageFile">رفع الصورة:</label>
          <input
            type="file"
            id="imageFile"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
          {formData.imageFile && (
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <img
                src={URL.createObjectURL(formData.imageFile)}
                alt="معاينة"
                style={{
                  maxWidth: '100%', // تحديد أقصى عرض
                  maxHeight: '200px', // تحديد أقصى ارتفاع
                  border: '1px solid #ccc', // إضافة حدود للصورة
                  padding: '5px', // إضافة مسافة داخلية للصورة
                }}
              />
            </div>
          )}
        </fieldset>


        <fieldset style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px' }}>
          <legend style={{ fontWeight: 'bold' }}>Category</legend>
          <label htmlFor="category">Select Category:</label>
          <select
            id="category"
            value={formData.category}
            onChange={(e) => handleInputChange('category', e.target.value)}
            required
          >
            <option value="">Select a category</option>
            <option value="طب-النفس">طب-النفس</option>
            <option value="الحمل-والولادة">الحمل-والولادة</option>
            <option value="الرعاية-المنزلية">الرعاية-المنزلية</option>
            <option value="التحاليل">التحاليل</option>
            <option value="تأخر-الإنجاب">تأخر-الإنجاب</option>
            <option value="عقم">عقم</option>
            <option value="العلاج-الطبيعي">العلاج-الطبيعي</option>
            <option value="مسالك-بولية">مسالك-بولية</option>
            <option value="الجراحة-العامة">الجراحة-العامة</option>
            <option value="الجهاز-الهضمي-والتنظير">الجهاز-الهضمي-والتنظير</option>
            <option value="المرأة">المرأة</option>
          </select>
        </fieldset>

        <fieldset style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px' }}>
          <legend style={{ fontWeight: 'bold' }}>أقسام المحتوى</legend>
          {sections.map((section, index) => (
            <div key={index} style={{ marginBottom: '15px', position: 'relative' }}>
              <label htmlFor={`section${index}_title`}>عنوان القسم {index + 1}:</label>
              <input
                type="text"
                id={`section${index}_title`}
                value={section.title}
                onChange={(e) => handleSectionChange(index, 'title', e.target.value)}
                placeholder="أدخل عنواناً للقسم"
                required
              />

              <label htmlFor={`section${index}_content`}>محتوى القسم {index + 1}:</label>
              <textarea
                id={`section${index}_content`}
                value={section.content}
                onChange={(e) => handleSectionChange(index, 'content', e.target.value)}
                placeholder="أدخل عناصر المحتوى لكل قسم، سطر لكل عنصر"
                rows="3"
                required
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  resize: 'vertical',
                  boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                  width: '700px',
                  height: '30px', 
                  direction: 'rtl',
                }}
              ></textarea>

              <button
                type="button"
                onClick={() => removeSection(index)}
                style={{ position: 'absolute', top: '0', right: '0', background: 'red', color: '#fff', border: 'none', padding: '5px' }}
              >
                X
              </button>
            </div>
          ))}
          <button type="button" onClick={addSection} style={{ background: '#28a745', color: '#fff', padding: '10px' }}>
            أضف قسماً جديداً
          </button>
        </fieldset>

        <h3>الخدمات المشمولة:</h3>
        <textarea
          value={formData.includedServices}
          onChange={(e) => handleInputChange('includedServices', e.target.value)}
          placeholder="أدخل محتوى الخدمات المشمولة، سطر لكل عنصر"
          rows="3"
          required
          style={{
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            resize: 'vertical',
             // يسمح بتغيير الحجم عمودياً
            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
             // إضافة ظل خفيف
            width: '700px', 
            // تحديد العرض
            height: '30px', 
            // تحديد الارتفاع
            direction: 'rtl', 
            // تعيين اتجاه النص من اليمين إلى اليسار
          }}
        ></textarea>

        <h3>الشروط الخاصة:</h3>
        <textarea
          value={formData.specialConditions}
          onChange={(e) => handleInputChange('specialConditions', e.target.value)}
          placeholder="أدخل محتوى الشروط الخاصة، سطر لكل عنصر"
          rows="3"
          required
          style={{
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            resize: 'vertical', 
            // يسمح بتغيير الحجم عمودياً
            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)', 
            // إضافة ظل خفيف
            width: '700px', 
            // تحديد العرض
            height: '30px',
             // تحديد الارتفاع
            direction: 'rtl', 
            // تعيين اتجاه النص من اليمين إلى اليسار
          }}
        ></textarea>


        {/* Footer Information */}
        <h3>معلومات التذييل:</h3>
        <label htmlFor="footer_cta_title">عنوان الدعوة إلى الإجراء:</label>
        <input
          type="text"
          id="footer_cta_title"
          value={formData.footerCTA.title}
          onChange={(e) => handleFooterChange('title', e.target.value)}
          placeholder="أدخل عنوان الدعوة إلى الإجراء"
          required
        />

        <label htmlFor="footer_cta_description">وصف الدعوة إلى الإجراء:</label>
        <textarea
          id="footer_cta_description"
          value={formData.footerCTA.description}
          onChange={(e) => handleFooterChange('description', e.target.value)}
          placeholder="أدخل وصف الدعوة إلى الإجراء"
          rows="3"
          required
          style={{
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            resize: 'vertical',
             // يسمح بتغيير الحجم عمودياً
            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
             // إضافة ظل خفيف
            width: '700px', 
            // تحديد العرض
            height: '30px',
             // تحديد الارتفاع
            direction: 'rtl', 
            // تعيين اتجاه النص من اليمين إلى اليسار
          }}
        ></textarea>

        <label htmlFor="footer_button_text">نص الزر:</label>
        <input
          type="text"
          id="footer_button_text"
          value={formData.footerCTA.buttonText}
          onChange={(e) => handleFooterChange('buttonText', e.target.value)}
          placeholder="أدخل نص الزر للدعوة إلى الإجراء"
          required
        />


        <div className="filterINput">
          <h3>الفلاتر</h3>



          <div className="filter-group">
            <label>الجنس:</label>
            <div className="filter-options">
              {['ذكر', 'أنثى'].map((gender) => (
                <label key={gender}>
                  <input
                    type="checkbox"
                    value={gender}
                    onChange={(e) =>
                      handleCheckboxChange('gender', e.target.value, e.target.checked)
                    }
                  />
                  {gender}
                </label>
              ))}
            </div>

            {/* Age Group Filter */}
            <label>الفئة العمرية:</label>
            <div className="filter-options">
              {['رضيع', 'طفل', 'مراهق', 'بالغ', 'جنين', 'كبار السن'].map((ageGroup) => (
                <label key={ageGroup}>
                  <input
                    type="checkbox"
                    value={ageGroup}
                    onChange={(e) =>
                      handleCheckboxChange('age_group', e.target.value, e.target.checked)
                    }
                  />
                  {ageGroup}
                </label>
              ))}
            </div>

            <label>مرحلة العلاج:</label>
            <div className="filter-options">
              {['وقاية', 'تشخيص', 'علاج', 'رعاية'].map((stage) => (
                <label key={stage}>
                  <input
                    type="checkbox"
                    value={stage}
                    onChange={(e) =>
                      handleCheckboxChange('treatment_stage', e.target.value, e.target.checked)
                    }
                  />
                  {stage}
                </label>
              ))}
            </div>

            {/* Medical Procedure Filter */}
            <label>الإجراء الطبي:</label>
            <div className="filter-options">
              {['ضغط', 'سكري', 'سمنة'].map((procedure) => (
                <label key={procedure}>
                  <input
                    type="checkbox"
                    value={procedure}
                    onChange={(e) =>
                      handleCheckboxChange('medical_procedure', e.target.value, e.target.checked)
                    }
                  />
                  {procedure}
                </label>
              ))}
            </div>

            {/* Service Duration Filter */}
            <label>مدة الخدمة:</label>
            <div className="filter-options">
              {['متوسطة', 'دائمة', 'مرة واحدة'].map((duration) => (
                <label key={duration}>
                  <input
                    type="checkbox"
                    value={duration}
                    onChange={(e) =>
                      handleCheckboxChange('service_duration', e.target.value, e.target.checked)
                    }
                  />
                  {duration}
                </label>
              ))}
            </div>

            <label>نوع التوفير:</label>
            <div className="filter-options">
              {['وقت', 'فلوس', 'الإثنان'].map((costType) => (
                <label key={costType}>
                  <input
                    type="checkbox"
                    value={costType}
                    onChange={(e) =>
                      handleCheckboxChange('cost_type', e.target.value, e.target.checked)
                    }
                  />
                  {costType}
                </label>
              ))}
            </div>

            {/* Service Location Filter */}
            <label>مكان الخدمة:</label>
            <div className="filter-options">
              {['في المستشفى', 'في المنزل'].map((location) => (
                <label key={location}>
                  <input
                    type="checkbox"
                    value={location}
                    onChange={(e) =>
                      handleCheckboxChange('service_location', e.target.value, e.target.checked)
                    }
                  />
                  {location}
                </label>
              ))}
            </div>

            {/* Procedure Type Filter */}
            <label>نوع الإجراء:</label>
            <div className="filter-options">
              {['منظار', 'عملية', 'تحليل', 'أشعة', 'كشف'].map((procedureType) => (
                <label key={procedureType}>
                  <input
                    type="checkbox"
                    value={procedureType}
                    onChange={(e) =>
                      handleCheckboxChange('procedure_type', e.target.value, e.target.checked)
                    }
                  />
                  {procedureType}
                </label>
              ))}
            </div>
          </div>

        </div>



        <div style={{ display: 'flex', gap: '10px' }}>
          <button type="submit" style={{ background: '#007bff', color: '#fff', padding: '10px', flex: '1' }}>
            حفظ الخدمة
          </button>
          <button type="button" onClick={resetForm} style={{ background: '#6c757d', color: '#fff', padding: '10px', flex: '1' }}>
            إعادة تعيين
          </button>
        </div>

      </form>


      <div>
        <div className="specialist">
          <h3>المتخصص 1:</h3>
          <table>
            <tbody>
              {[
                'طب-منزلي',
                'الطب-الوقائي',
                'الطب-التشخيصي',
                'الطب-العلاجي',
                'الطب-الجراحي',
                'الطب-الباطني',
                'الطب-النفسي',
                'طب-الأطفال',
                'طب-النساء-والتوليد',
                'طب-الأسنان',
                'الطب-الطبيعي-وإعادة-التأهيل',
              ].map((specialist, index) => (
                <tr key={index}>
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        value={specialist}
                        onChange={(e) =>
                          handleSpecialisteChange('specialiste1', specialist, e.target.checked)
                        }
                      />
                      {specialist}
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3>المتخصص 2:</h3>
          <table>
            <tbody>
              {[
                "طب-منزلي", "الطب-الوقائي", "الطب-التشخيصي", "الطب-العلاجي", "الطب-الجراحي",
                "الطب-الباطني", "الطب-النفسي", "طب-الأطفال", "طب-النساء-والتوليد", "طب-الأسنان",
                "الطب-الطبيعي-وإعادة-التأهيل", "الصحة-العامة", "التطعيمات", "طب-الأوبئة",
                "الصحة-المهنية", "الوقاية-الغذائية", "الوقاية-من-الأمراض-المزمنة", "طب-البيئي",
                "التثقيف-الصحي", "الفحص-المبكر", "الوقاية-من-الإدمان", "الأشعة-التشخيصية",
                "التصوير-بالرنين-المغناطيسي", "التصوير-بالموجات-فوق-الصوتية", "التحاليل-المخبرية",
                "التنظير-الداخلي", "علم-الأمراض-التشريحي", "التشخيص-الجيني", "التخطيط-الكهربائي",
                "الطب-النووي", "اختبارات-الجهاز-التنفسي", "العلاج-الدوائي", "العلاج-الكيميائي",
                "العلاج-الإشعاعي", "العلاج-الجراحي", "العلاج-الطبيعي",
                "العلاج-النفسي", "العلاج-المناعي", "العلاج-الهرموني", "العلاج-البديل-والتكميلي",
                "الجراحة-العامة", "جراحة-القلب-والصدر", "جراحة-الأعصاب", "جراحة-العظام",
                "الجراحة-التجميلية", "جراحة-الأوعية-الدموية", "جراحة-الأطفال", "جراحة-العيون",
                "جراحة-الجهاز-الهضمي", "جراحة-المسالك-البولية", "أمراض-القلب", "أمراض-الجهاز-التنفسي",
                "أمراض-الجهاز-الهضمي", "أمراض-الكبد", "أمراض-الكلى", "أمراض-الدم", "الأمراض-المعدية",
                "أمراض-الغدد-الصماء", "أمراض-الروماتيزم", "الأمراض-المناعية", "اضطرابات-القلق",
                "الاكتئاب", "الفصام", "اضطرابات-الأكل", "اضطرابات-النوم", "الاضطرابات-ثنائية-القطب",
                "اضطرابات-الوسواس-القهري", "اضطراب-ما-بعد-الصدمة", "إدمان-المخدرات-والكحول",
                "الاضطرابات-الشخصية", "طب-حديثي-الولادة", "أمراض-الأطفال-المعدية",
                "أمراض-الجهاز-التنفسي-للأطفال", "طب-القلب-للأطفال", "الأمراض-الجينية",
                "أمراض-الجهاز-الهضمي-للأطفال", "طب-النمو-والتطور", "طب-الأطفال-العام",
                "العناية-المركزة-للأطفال", "طب-الطوارئ-للأطفال", "طب-التوليد", "طب-الخصوبة",
                "جمال-المراه", "أمراض-الجهاز-التناسلي", "تنظيم-الأسرة", "طب-الأورام-النسائية",
                "جراحات-الجهاز-التناسلي", "طب-سن-اليأس", "علاج-العقم", "تشخيص-وعلاج-الالتهابات-النسائية",
                "الجراحات-التجميلية-النسائية", "علاج-التسوس", "تقويم-الأسنان", "جراحة-الفم",
                "طب-أسنان-الأطفال", "علاج-أمراض-اللثة", "تركيبات-الأسنان", "علاج-الجذور",
                "تجميل-الأسنان", "طب-الفم", "أمراض-مفصل-الفك", "العلاج-الوظيفي",
                "إعادة-تأهيل-الإصابات-الرياضية", "إعادة-التأهيل-القلبي", "إعادة-تأهيل-السكتة-الدماغية",
                "العلاج-بالماء", "إعادة-تأهيل-العمود-الفقري", "إعادة-تأهيل-ما-بعد-العمليات-الجراحية",
                "العلاج-بالكهرباء", "العلاج-الوظيفي-للأطراف-الصناعية"
              ].map((specialist, index) => (
                <tr key={index}>
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        value={specialist}
                        onChange={(e) =>
                          handleSpecialisteChange('specialiste2', specialist, e.target.checked)
                        }
                      />
                      {specialist}
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button onClick={handleUpload} className="upload-button">
          تحميل التخصصات
        </button>

      </div>

    </div>

  );
};




























const Seetheinfomation = () => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [searchServiceId, setSearchServiceId] = useState('');
  const [filteredService, setFilteredService] = useState(null);
  const [hospitalData, setHospitalData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [filteredHospital, setFilteredHospital] = useState(null);
  const [searchHospitalId, setSearchHospitalId] = useState('');
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const HOSPITAL_DATA_API_URL = process.env.REACT_APP_UPDATE_HOSPITAL_DATA_ENDPOINT;

  const fetchHospitalData = async () => {
    try {
      const response = await fetch(HOSPITAL_DATA_API_URL);
      const data = await response.json();
      setHospitalData(data); // تخزين بيانات المستشفيات في الحالة
    } catch (error) {
      console.error('خطأ أثناء جلب بيانات المستشفيات:', error);
    }
  };
  

  const SERVICE_DATA_API_URL = process.env.REACT_APP_UPDATE_SERVICE_DATA_ENDPOINT;

  const fetchServiceData = async () => {
    try {
      const response = await fetch(SERVICE_DATA_API_URL);
      const data = await response.json();
      setServiceData(data); // تخزين بيانات الخدمات في الحالة
    } catch (error) {
      console.error('خطأ أثناء جلب بيانات الخدمات:', error);
    }
  };
  

  const handleServiceSearch = () => {
    const result = serviceData.find((service) => service.service_id === searchServiceId);
    if (result) {
      setFilteredService(result); // تعيين الخدمة التي تم العثور عليها
    } else {
      alert('لم يتم العثور على الخدمة.');
      setFilteredService(null); // إعادة تعيين إذا لم يتم العثور
    }
  };

  const handleHospitalSearch = () => {
    console.log('جارٍ البحث عن معرف الخدمة:', searchHospitalId); // سجل لمراقبة البحث
    console.log('بيانات المستشفيات المتاحة:', hospitalData); // سجل بيانات المستشفيات

    const trimmedServiceId = searchHospitalId.trim(); // إزالة المسافات الزائدة
    const result = hospitalData.find((hospital) => {
      console.log('مقارنة معرفات الخدمات:', hospital.serviceIds, 'مع', trimmedServiceId); // سجل المقارنة
      return hospital.serviceIds === trimmedServiceId;
    });

    if (result) {
      setFilteredHospital(result);
      console.log('تم العثور على المستشفى:', result);
    } else {
      alert('لم يتم العثور على المستشفى.');
      setFilteredHospital(null);
    }
  };



  const updateHospitalData = async () => {
    if (!filteredHospital) {
      alert('لم يتم تحديد مستشفى للتحديث.');
      return;
    }

    try {
      console.log('جارٍ تحديث بيانات المستشفى...');
      console.log('معرفات الخدمات:', filteredHospital.serviceIds);
      console.log('البيانات المحدثة:', filteredHospital);

      const UPDATE_HOSPITAL_SERVICES_API_URL = process.env.REACT_APP_UPDATE_HOSPITAL_SERVICES_ENDPOINT;

      const response = await fetch(UPDATE_HOSPITAL_SERVICES_API_URL, {
        method: 'POST', // نوع الطلب هو POST
        headers: {
          'Content-Type': 'application/json', // نوع البيانات المرسلة JSON
        },
        body: JSON.stringify({
          serviceIds: filteredHospital.serviceIds, // استخدام معرفات الخدمات كمُعرف فريد
          data: filteredHospital, // بيانات المستشفى المحدثة
          table: 'additionalhospitalServices', // تحديد الجدول
        }),
      });
      

      console.log('حالة الاستجابة:', response.status);

      if (response.ok) {
        const responseData = await response.json(); // تحليل استجابة الخادم بصيغة JSON
        console.log('استجابة الخادم:', responseData);

        alert('تم تحديث بيانات المستشفى بنجاح!');
        fetchHospitalData(); // تحديث البيانات
      } else {
        const errorText = await response.text();
        console.error('خطأ أثناء تحديث بيانات المستشفى:', errorText);
      }
    } catch (error) {
      console.error('خطأ أثناء تحديث بيانات المستشفى:', error);
    }
  };




  const updateData = async (id, data, table) => {
    try {
      console.log("Preparing to update data...");
      console.log("ID:", id);
      console.log("Data being sent to backend:", JSON.stringify(data, null, 2)); // Format data for readability
      console.log("Table:", table);
  
      // Make the POST request to the backend
      const UPDATE_DATA_HELP_API_URL = process.env.REACT_APP_UPDATE_DATA_HELP_ENDPOINT;

      const response = await fetch(UPDATE_DATA_HELP_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Specify JSON format
        },
        body: JSON.stringify({ id, data, table }), // Include id, data, and table name in the request body
      });
      
  
      if (response.ok) {
        const result = await response.json();
        console.log("Backend response:", result);
  
        // Check for backend-specific success confirmation
        if (result.success) {
          alert('تم تحديث البيانات بنجاح!');
  
          // Refresh data based on the table being updated
          if (table === "additional_Services") {
            fetchServiceData(); // Reload service data
          } else if (table === "additionalhospitalServices") {
            fetchHospitalData(); // Reload hospital data
          }
        } else {
          console.error("Backend error:", result.error || "Unknown error occurred.");
          alert(result.error || "فشل في تحديث البيانات. الرجاء التحقق من السجلات.");
        }
      } else {
        const errorText = await response.text();
        console.error("Error response from backend:", errorText);
        alert("فشل في تحديث البيانات. الرجاء التحقق من السجلات.");
      }
    } catch (error) {
      console.error("Error during data update:", error);
      alert("حدث خطأ أثناء التحديث. الرجاء المحاولة مرة أخرى.");
    }
  };
  


  const handleImageChange = async (event) => {
    const file = event.target.files[0]; // الحصول على الملف الذي تم تحميله
    if (file) {
      try {
        const storageRef = ref(storage, `hospital_images/${file.name}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        if (filteredHospital) {
          await updateData(filteredHospital.id, { logo: downloadURL }, "additionalhospitalServices");
          setFilteredHospital({ ...filteredHospital, logo: downloadURL });
          alert("تم تحميل وتحديث شعار المستشفى بنجاح!");
        }
        else if (filteredService) {
          await updateData(filteredService.id, { image: downloadURL }, "additional_Services");
          setFilteredService({ ...filteredService, image: downloadURL });
          alert("تم تحميل وتحديث صورة الخدمة بنجاح!");
        }
      } catch (error) {
        console.error("خطأ أثناء تحميل الصورة:", error);
        alert("فشل في تحميل الصورة. الرجاء المحاولة مرة أخرى.");
      }
    }
  };


  // Fetch data on component mount
  useEffect(() => {
    fetchServiceData();
    fetchHospitalData();
  }, []);
  
  const changeImageLink = async (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file); // Log the selected file
  
    if (!file) {
      alert("No file selected.");
      console.error("File selection failed. No file provided.");
      return;
    }
  
    try {
      console.log("Preparing to upload the file...");
  
      // Create a reference in Firebase Storage
      const storageRef = ref(storage, `uploaded_images/${file.name}`);
      console.log("Storage reference created:", storageRef);
  
      // Upload the file
      const uploadResult = await uploadBytes(storageRef, file);
      console.log("File uploaded successfully. Upload result:", uploadResult);
  
      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);
      console.log("Download URL retrieved:", downloadURL);
  
      if (filteredService) {
        console.log("Updating filteredService state and backend...");
        const updatedService = { ...filteredService, image: downloadURL };
  
        // Update the backend with the new data
        await updateData(filteredService.id, updatedService, "additional_Services");
  
        // Update the local state to reflect the new image
        setFilteredService(updatedService);
        console.log("FilteredService updated with new image URL:", updatedService);
  
        alert("Image uploaded and link updated successfully!");
      } else {
        console.error("No service selected to update the image.");
        alert("No service selected to update the image.");
      }
    } catch (error) {
      console.error("Error during file upload or URL retrieval:", error);
      alert("Failed to upload the image. Please try again.");
    }
  };
  
  

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ textAlign: 'center' }}>Manage Information</h1>

      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <button
          onClick={() => {
            setSelectedSection('hospital');
            fetchHospitalData();
          }}
          style={{ marginRight: '10px', padding: '10px 20px', fontSize: '16px' }}
        >
          Hospital
        </button>
        <button
          onClick={() => {
            setSelectedSection('service');
            fetchServiceData();
          }}
          style={{ padding: '10px 20px', fontSize: '16px' }}
        >
          Service
        </button>
      </div>

      {selectedSection === 'hospital' && (
        <div>
          <h2>معلومات المستشفى</h2>
          <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <input
              type="text"
              placeholder='أدخل معرف الخدمة (مثل "123")'
              value={searchHospitalId} // State already stores the value with quotes
              onChange={(e) => {
                const inputValue = e.target.value;
                if (!inputValue.startsWith('"') || !inputValue.endsWith('"')) {
                  setSearchHospitalId(`"${inputValue.replace(/"/g, "")}"`); // Wraps the input in quotes
                } else {
                  setSearchHospitalId(inputValue); // Keeps the input as-is if it's already quoted
                }
              }}
              style={{ padding: "10px", fontSize: "16px", marginRight: "10px" }}
            />

            <button
              onClick={handleHospitalSearch} // Dedicated function for hospital search
              style={{ padding: '10px 20px', fontSize: '16px' }}
            >
              بحث
            </button>
          </div>

          {filteredHospital ? (
            <div>
              {/* Hospital Logo */}
              <div
                className="catalog-card"
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={filteredHospital.logo} // Display hospital logo
                  alt="شعار المستشفى"
                  style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    marginRight: '10px',
                  }}
                />
                <button
                  onClick={() => document.getElementById('hospitalLogoInput').click()} // Trigger file input
                  style={{
                    padding: '10px 20px',
                    fontSize: '14px',
                    backgroundColor: '#007BFF',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  تغيير الشعار
                </button>
                <input
                  id="hospitalLogoInput"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => handleImageChange(e)} // Reuse handleImageChange for logo update
                />
              </div>

              {/* Editable Fields */}
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>المعرف:</strong> {filteredHospital.id}
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>اسم المستشفى:</strong>
                <input
                  type="text"
                  value={filteredHospital.hospitalName} // Hospital Name field
                  onChange={(e) =>
                    setFilteredHospital({ ...filteredHospital, hospitalName: e.target.value })
                  }
                  style={{
                    padding: '5px',
                    fontSize: '14px',
                    marginLeft: '10px',
                    width: '80%',
                  }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الموقع:</strong>
                <input
                  type="text"
                  value={filteredHospital.location} // Location field
                  onChange={(e) =>
                    setFilteredHospital({ ...filteredHospital, location: e.target.value })
                  }
                  style={{
                    padding: '5px',
                    fontSize: '14px',
                    marginLeft: '10px',
                    width: '80%',
                  }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>العنوان الفرعي:</strong>
                <input
                  type="text"
                  value={filteredHospital.subtitle} // Subtitle field
                  onChange={(e) =>
                    setFilteredHospital({ ...filteredHospital, subtitle: e.target.value })
                  }
                  style={{
                    padding: '5px',
                    fontSize: '14px',
                    marginLeft: '10px',
                    width: '80%',
                  }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الخدمات:</strong>
                <textarea
                  value={filteredHospital.services} // Services field
                  onChange={(e) =>
                    setFilteredHospital({ ...filteredHospital, services: e.target.value })
                  }
                  style={{
                    padding: '5px',
                    fontSize: '14px',
                    marginLeft: '10px',
                    width: '80%',
                  }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>معرفات الخدمات:</strong>
                <input
                  type="text"
                  value={filteredHospital.serviceIds} // Service IDs field
                  onChange={(e) =>
                    setFilteredHospital({ ...filteredHospital, serviceIds: e.target.value })
                  }
                  style={{
                    padding: '5px',
                    fontSize: '14px',
                    marginLeft: '10px',
                    width: '80%',
                  }}
                />
              </div>

              {/* Update Button */}
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <button
                  onClick={() => updateHospitalData()}
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#4CAF50',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  تحديث المستشفى
                </button>
              </div>

            </div>
          ) : (
            <p style={{ textAlign: 'center', marginTop: '20px' }}>
              الرجاء إدخال معرف المستشفى للبحث.
            </p>
          )}
        </div>
      )}






      {selectedSection === 'service' && (
        <div>
          <h2>معلومات الخدمة</h2>
          <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <input
              type="text"
              placeholder="أدخل معرف الخدمة"
              value={searchServiceId}
              onChange={(e) => setSearchServiceId(e.target.value)}
              style={{ padding: '10px', fontSize: '16px', marginRight: '10px' }}
            />
            <button
              onClick={handleServiceSearch}
              style={{ padding: '10px 20px', fontSize: '16px' }}
            >
              بحث
            </button>
          </div>

          {filteredService ? (
            <div>
              <div
                className="catalog-card"
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* Image Display */}
                <img
                  src={filteredService.image}
                  alt="الخدمة"
                  style={{
                    width: '150px',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    marginRight: '10px',
                  }}
                />

                {/* Change Image Button */}
                <button
                  onClick={() => document.getElementById('fileInput').click()} // Trigger file input
                  style={{
                    padding: '10px 20px',
                    fontSize: '14px',
                    backgroundColor: '#007BFF',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  تغيير الصورة
                </button>

                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => changeImageLink(e)} // Call the new function for uploading
                />
              </div>


              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>المعرف:</strong> {filteredService.id}
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>معرف الخدمة:</strong> {filteredService.service_id}
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>العنوان:</strong>
                <input
                  type="text"
                  value={filteredService.title}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, title: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الملخص:</strong>
                <input
                  type="text"
                  value={filteredService.summary}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, summary: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الوصف:</strong>
                <textarea
                  value={filteredService.description}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, description: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الفئة:</strong>
                <input
                  type="text"
                  value={filteredService.category}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, category: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الأقسام:</strong>
                <input
                  type="text"
                  value={filteredService.sections}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, sections: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الخدمات المشمولة:</strong>
                <input
                  type="text"
                  value={filteredService.included_services}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, included_services: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>الشروط الخاصة:</strong>
                <input
                  type="text"
                  value={filteredService.special_conditions}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, special_conditions: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>التذييل:</strong>
                <input
                  type="text"
                  value={filteredService.footer}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, footer: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>المرشحات:</strong>
                <input
                  type="text"
                  value={filteredService.filters}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, filters: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>التخصص 1:</strong>
                <input
                  type="text"
                  value={filteredService.Specialiste1}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, Specialiste1: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div className="catalog-card" style={{ marginBottom: '10px' }}>
                <strong>التخصص 2:</strong>
                <input
                  type="text"
                  value={filteredService.Specialiste2}
                  onChange={(e) =>
                    setFilteredService({ ...filteredService, Specialiste2: e.target.value })
                  }
                  style={{ padding: '5px', fontSize: '14px', marginLeft: '10px', width: '80%' }}
                />
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
  <button
    onClick={() =>
      updateData(filteredService.id, filteredService, "additional_Services")
    }
    style={{
      padding: '10px 20px',
      fontSize: '16px',
      backgroundColor: '#4CAF50',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    }}
  >
    تحديث
  </button>
</div>

            </div>
          ) : (
            <p style={{ textAlign: 'center', marginTop: '20px' }}>الرجاء إدخال معرف الخدمة للبحث.</p>
          )}
        </div>

      )}

    </div>
  )
}






export default DataEntry;





































