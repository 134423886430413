import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar.js'; // Import Navbar
import LoginPage from './pages/LoginPage.js';
import SignupPage from './pages/SignupPage.js';
import DashboardPage from './pages/DashboardPage.js';
import AmbassadorPage from './pages/AmbassadorPage.js';
import TopManagementPage from './pages/TopManagementPage.js';
import ProviderManagement from './pages/ProviderManagement.js';
import FinancePage from './pages/FinancePage.js';
import Our_Partners_Services from './pages/Our_Partners_Services.js';
import DataEntry from './pages/DataEntry.js';
import CustomerManagementPage from './pages/CustomerManagementPage.js';
import PendingApprovalPage from './pages/PendingApprovalPage.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import NotFoundPage from './pages/NotFoundPage.js';
import { UserProvider } from './context/UserContext';

import './App.css';

function App() {
  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Disable F12 and developer tools shortcuts
    const handleKeyDown = (event) => {
      if (
        event.key === 'F12' || // Disable F12
        (event.ctrlKey && event.shiftKey && event.key === 'I') || // Disable Ctrl+Shift+I
        (event.ctrlKey && event.shiftKey && event.key === 'C') || // Disable Ctrl+Shift+C
        (event.ctrlKey && event.key === 'U') // Disable Ctrl+U (View Source)
      ) {
        event.preventDefault();
      }
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <UserProvider>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Routes>
            {/* Unprotected Routes */}
            <Route path="/" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="*" element={<NotFoundPage />} />

            {/* Protected Routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute requiresApproval={true}>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/data-entry"
              element={
                <ProtectedRoute allowedRoles={['Data Entry']}>
                  <DataEntry />
                </ProtectedRoute>
              }
            />
            <Route
              path="/finance"
              element={
                <ProtectedRoute allowedRoles={['Finance']}>
                  <FinancePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/our-partners-services"
              element={
                <ProtectedRoute allowedRoles={['Our Partners Services']}>
                  <Our_Partners_Services />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer-management"
              element={
                <ProtectedRoute allowedRoles={['Customer Management']}>
                  <CustomerManagementPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/top-management"
              element={
                <ProtectedRoute allowedRoles={['Top Management']}>
                  <TopManagementPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/provider-management"
              element={
                <ProtectedRoute allowedRoles={['Provider Management']}>
                  <ProviderManagement />
                </ProtectedRoute>
              }
            />

            <Route
              path="/ambassador"
              element={
                <ProtectedRoute allowedRoles={['Ambassador']}>
                  <AmbassadorPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pending-approval"
              element={
                <ProtectedRoute requiresApproval={false}>
                  <PendingApprovalPage />
                </ProtectedRoute>
              }
            />

          </Routes>
        </div>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;