import React, { useState, useContext } from 'react';
import './PendingApprovalPage.css';
import { UserContext } from '../context/UserContext';

function PendingApprovalPage() {
  const { user, setIsApproved } = useContext(UserContext); // Access user and approval state
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true); // Show loading state
    try {
      if (!user || !user.phone) {
        alert('انتهت الجلسة أو لم يتم العثور على رقم الهاتف. يرجى تسجيل الدخول مرة أخرى.');
        window.location.href = '/'; // Redirect to login
        return;
      }

      // API call to check approval status
      const response = await fetch(process.env.REACT_APP_VERIFY_APPROVAL_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: user.phone }), // Send phone number in request body
      });

      // Handle non-OK responses
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }

      // Parse JSON response
      const data = await response.json();

      // Handle different approval statuses
      if (data.status === 'approved') {
        setIsApproved(true); // Update approval status in context
        alert('تمت الموافقة على حسابك! سيتم توجيهك إلى لوحة التحكم...');
        window.location.href = '/dashboard'; // Redirect to dashboard
      } else if (data.status === 'pending') {
        setIsApproved(false); // Update approval status in context
        alert('حسابك لا يزال قيد المراجعة.');
      } else {
        alert(data.message || 'حدث خطأ أثناء التحقق من حالة الموافقة. يرجى المحاولة مرة أخرى.');
      }
    } catch (error) {
      console.error('Error refreshing approval status:', error);
      alert('تعذر تحديث الحالة. يرجى المحاولة لاحقًا.');
    } finally {
      setRefreshing(false); // Hide loading state
    }
  };

  return (
    <div className="pending-approval-container">
      <div className="pending-approval-card">
        <h1 className="pending-approval-title">حسابك قيد المراجعة</h1>
        <p className="pending-approval-message">
          حسابك قيد المراجعة من قبل الإدارة. تضمن هذه العملية أن جميع المستخدمين يستوفون معايير منصتنا.
        </p>
        <p className="pending-approval-message">
          ستتلقى إشعارًا بمجرد الموافقة على حسابك. شكرًا لتفهمك وصبرك.
        </p>
        <div className="pending-approval-actions">
          <button
            className="pending-approval-button"
            onClick={handleRefresh}
            disabled={refreshing}
          >
            {refreshing ? 'جاري التحقق من الحالة...' : 'تحديث الحالة'}
          </button>
          <button
            className="pending-approval-contact-button"
            onClick={() => window.location.href = 'mailto:support@lahalak.sa'}
          >
            التواصل مع الدعم الفني
          </button>
        </div>
      </div>
    </div>
  );
}

export default PendingApprovalPage;
