import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.text}>
        Sorry, the page you are looking for does not exist. It might have been moved or deleted.
      </p>
      <Link to="/" style={styles.link}>
        Go Back to Home
      </Link>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '100px',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    fontSize: '3rem',
    color: '#333',
    marginBottom: '20px',
  },
  text: {
    fontSize: '1.2rem',
    color: '#666',
    marginBottom: '30px',
  },
  link: {
    fontSize: '1rem',
    color: '#007BFF',
    textDecoration: 'none',
    border: '1px solid #007BFF',
    padding: '10px 20px',
    borderRadius: '5px',
    transition: 'all 0.3s ease',
  },
};

export default NotFoundPage;
