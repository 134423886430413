import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, allowedRoles = [], requiresApproval = false }) {
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    // Retrieve data from localStorage
    const storedToken = localStorage.getItem('authToken');
    const storedUserRole = localStorage.getItem('userRole');
    const storedIsApproved = localStorage.getItem('isApproved') === 'true';

    setToken(storedToken);
    setUserRole(storedUserRole);
    setIsApproved(storedIsApproved);

    setIsLoading(false); // Mark as loaded
  }, []);

  useEffect(() => {
    console.log('Token:', token);
    console.log('UserRole:', userRole);
    console.log('AllowedRoles:', allowedRoles);
    console.log('IsApproved:', isApproved);
  }, [token, userRole, isApproved]);

  if (isLoading) {
    return <p>Loading...</p>; // Show loading message while initializing
  }

  // Redirect to login if no token is found
  if (!token) {
    console.warn('No token found, redirecting to login.');
    return <Navigate to="/" replace />;
  }

  try {
    const payload = token && JSON.parse(atob(token.split('.')[1] || '')); // Safeguard against malformed tokens
    if (!payload || !payload.exp) {
      throw new Error('Invalid token payload');
    }

    // Check if the token has expired
    if (Date.now() >= payload.exp * 1000) {
      console.warn('Token expired, redirecting to login.');
      localStorage.clear(); // Clear all session-related data
      alert('Session expired. Please log in again.');
      return <Navigate to="/" replace />;
    }

    // Redirect to pending approval if `requiresApproval` is true and the user is not approved
    if (requiresApproval && !isApproved) {
      console.log('User not approved, redirecting to pending approval page.');
      return <Navigate to="/pending-approval" replace />;
    }

    // Check if the user's role matches the allowed roles
    if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
      console.warn(`Unauthorized role (${userRole}), redirecting to unauthorized page.`);
      return <Navigate to="/unauthorized" replace />; // Redirect to unauthorized page
    }
  } catch (error) {
    console.error('Error decoding or processing token:', error);
    localStorage.clear(); // Clear all session-related data
    return <Navigate to="/" replace />;
  }

  console.log('Access granted, rendering children.');
  return children;
}

export default ProtectedRoute;
