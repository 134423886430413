import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext'; // Import UserContext
import './LoginPage.css';
import logo from '../assets/loba2.png';

function LoginPage() {
  const navigate = useNavigate();
  const { setUser, setAuthToken, setUserRole, setIsApproved } = useContext(UserContext); // Use context
  const [id, setId] = useState(''); // State for ID
  const [password, setPassword] = useState(''); // State for password
  const [error, setError] = useState(''); // State for error message
  const [loading, setLoading] = useState(false); // State for loading indicator

  // Format phone number to include country code
  const formatPhoneNumber = (phone) => {
    if (phone.startsWith('0')) {
      return `966${phone.slice(1)}`;
    }
    return phone;
  };

  // Handle login form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state
    setLoading(true); // Show loading spinner

    const formattedId = formatPhoneNumber(id); // Format the ID
    console.log('Formatted ID:', formattedId); // Log the formatted ID

    try {
      const response = await fetch(process.env.REACT_APP_LOGIN_API_URL, { // Use environment variable for the URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: formattedId, password }),
      });

      console.log('Request Payload:', { id: formattedId, password }); // Log the request payload

      if (!response.ok) {
        console.error('HTTP Error:', response.status);
        throw new Error(`Server error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response :', data);

      if (data.status === 'success') {
        // Extract user details
        const userData = {
          id: data.data.userId || null, // Ensure `id` is mapped correctly
          name: data.data.name,
          email: data.data.email,
          phone: data.data.phone,
          role: data.data.role,
          isVerified: data.data.is_verified === 1,
          isApproved: data.data.approved === 1,
        };

        // Update UserContext
        setUser(userData);
        setAuthToken(data.token);
        setUserRole(userData.role);
        setIsApproved(userData.isApproved);

        console.log('User Context Updated:', userData);

        // Navigate based on user verification and approval status
        if (userData.isVerified && userData.isApproved) {
          console.log(`User approved, redirecting to ${userData.role} page.`);
          navigate(`/${userData.role.toLowerCase().replace(/\s+/g, '-')}`);
        } else {
          console.log('User not approved or not verified, redirecting to pending-approval.');
          navigate('/pending-approval');
        }
      } else {
        console.error('Login Failed:', data.message);
        setError(data.message || 'Invalid ID or password.');
      }
    } catch (err) {
      console.error('Error during login:', err.message || err);
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false); // Hide the loading spinner
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={logo} alt="Logo" className="login-logo" />
        <h1 className="login-title">لوحة التحكم</h1>
        <h2 className="login-subtitle">Dashboard</h2>
        <form onSubmit={handleLogin}>
          {/* Input for ID */}
          <div className="input-container">
            <label className="input-label">
              الرقم التعريفي<br />
              <span>رقم الهاتف</span>
            </label>

            <input
              type="text"
              className="login-input"
              placeholder="أدخل رقم الهاتف"
              value={id}
              onChange={(e) => setId(e.target.value)}
              required
            />
          </div>

          {/* Input for Password */}
          <div className="input-container">
            <label className="input-label">
              كلمة السر<br />
              <span>Password</span>
            </label>
            <input
              type="password"
              className="login-input"
              placeholder="أدخل كلمة المرور"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
          </div>

          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Loading...' : 'التالي'}
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
