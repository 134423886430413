
import React, { useState, useEffect, useMemo, useContext } from "react";
import './OurPartnersServices.css';
import locationIcon from '../assets/images/location.png';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext'; // Import UserContext
import EditIcon from '@mui/icons-material/Edit';

const Our_Partners_Services = () => {
    const [services, setServices] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [value, setValue] = useState('');
    const [valuesList, setValuesList] = useState([]);
    const servicesPerPage = 50;
    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [displayedServices, setDisplayedServices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeView, setActiveView] = useState('display');
    const { user, checkedRequests } = useContext(UserContext); // Consume UserContext

    const [formData, setFormData] = useState({
        centerName: '',
        city: '',
        district: '',
        logo: null,

    });
    const [isSubmitting, setIsSubmitting] = useState(false); // Define state for `isSubmitting`

    const handleAddNewField = (id) => {
        setServices((prevServices) =>
            prevServices.map((service) =>
                service.id === id
                    ? { ...service, valuesList: [...service.valuesList, ""] }
                    : service
            )
        );
    };


    const handleRemoveField = (id, index) => {
        setServices((prevServices) =>
            prevServices.map((service) =>
                service.id === id
                    ? { ...service, valuesList: service.valuesList.filter((_, i) => i !== index) }
                    : service
            )
        );
    };
    const handleChange = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleValueChange = (id, index, newValue) => {
        setServices((prevServices) =>
            prevServices.map((service) =>
                service.id === id
                    ? {
                        ...service,
                        valuesList: service.valuesList.map((value, i) =>
                            i === index ? newValue : value
                        ),
                    }
                    : service
            )
        );
    };
    const handleSubmit = (event, serviceId, imageUrl) => {
        if (event) {
            event.preventDefault();
        }


        // Validate required fields
        if (!formData.centerName || !formData.city || !formData.district) {
            alert('يرجى ملء جميع الحقول المطلوبة');
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('centerName', formData.centerName || '');
        formDataToSend.append('city', formData.city || '');
        formDataToSend.append('district', formData.district || '');

        if (formData.logo) {
            formDataToSend.append('logo', formData.logo);
        }
        if (serviceId) {
            formDataToSend.append('serviceId', serviceId);
        }
        if (imageUrl) {
            formDataToSend.append('imageUrl', imageUrl);
        }

        // Add user's phone number
        if (user?.phone) {
            formDataToSend.append('phoneNumber', user.phone);
        }

        // Ensure priceInstitution is passed correctly
        if (modalData?.priceInstitution) {
            formDataToSend.append('priceInstitution', modalData.priceInstitution);
        }

        // Process services
        const filteredServices = services
            .map((service) => {
                const options = {
                    price: service.priceLahaLak || null,
                    optionName: service.nameprovidedbyyou || null,
                };

                const bookingDetails = service.valuesList
                    .filter((value) => value.trim() !== '')
                    .map((value, index) => ({
                        [`description${index + 1}`]: value,
                    }));

                if (options.price || options.optionName || bookingDetails.length > 0) {
                    return {
                        options: [options],
                        bookingDetails,
                    };
                }

                return null;
            })
            .filter((service) => service !== null);

        // Extract the first service or use an empty object
        const serviceToSend = filteredServices.length > 0 ? filteredServices[0] : {};

        // Append as an object instead of an array
        formDataToSend.append('services', JSON.stringify(serviceToSend));


        // Display a loading state (if needed)
        setIsSubmitting(true);

        const apiUrl = process.env.REACT_APP_SUBMIT_PARTNER_SERVICES_URL;


        fetch(apiUrl, {
            method: 'POST',
            body: formDataToSend,
        })
            .then(async (response) => {
                const rawText = await response.text();

                if (!response.ok) {
                    throw new Error(`Failed to submit data: ${response.statusText}`);
                }
                return JSON.parse(rawText);
            })
            .then((data) => {
                alert('تم إرسال البيانات بنجاح');
                setIsModalOpen(false);
                setFormData({
                    centerName: '',
                    city: '',
                    district: '',
                    logo: null,
                });
            })
            .catch((error) => {
                alert(`حدث خطأ أثناء إرسال البيانات: ${error.message}`);
            })
            .finally(() => {
                // Reset loading state
                setIsSubmitting(false);
            });
    };







    const memoizedFilteredServices = useMemo(() => {
        return services
            .map((service) => {
                const options = {
                    price: service.priceLahaLak || null,
                    optionName: service.nameprovidedbyyou || null,
                };

                const bookingDetails = service.valuesList
                    .filter((value) => value.trim() !== "")
                    .map((value, index) => ({
                        [`description${index + 1}`]: value,
                    }));

                if (options.price || options.optionName || bookingDetails.length > 0) {
                    return {
                        options: [options],
                        bookingDetails,
                    };
                }

                return null;
            })
            .filter((service) => service !== null);
    }, [services]);



    const handleSumilation = (service) => {
        const updatedModalData = {
            ...service,
            priceLahaLak: service.priceLahaLak,
            serviceId: service.serviceId,
            nameprovidedbyyou: service.nameprovidedbyyou,
            priceInstitution: service.priceInstitution,
            centerName: formData.centerName,
            location: `${formData.city}`,
            district: `${formData.district}`,
            valuesList: service.valuesList,
            logo: formData.logo ? URL.createObjectURL(formData.logo) : null,
        };


        setModalData(updatedModalData);
        setIsModalOpen(true);
    };


    const closeModal = () => {
        setIsModalOpen(false);
    };
    const filteredServices = services.filter((service) => {
        const query = searchQuery.toLowerCase();

        return (
            service.name.toLowerCase().includes(query) ||
            service.summery.toLowerCase().includes(query) ||
            service.includedServices.content.some((item) =>
                item.toLowerCase().includes(query)
            )
        );
    });
    useEffect(() => {
        const endpoint = process.env.REACT_APP_PARTNER_SERVICES_URL;

        if (!endpoint) {
            return;
        }

        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch services");
                }
                return response.json();
            })
            .then((data) => {
                const servicesData = data.map((service) => ({
                    id: service.serviceId,
                    code: service.serviceId,
                    name: service.title,
                    summery: service.summary,
                    includedServices: service.includedServices || { title: "", content: [] },
                    priceLahaLak: service.priceLahaLak,
                    nameprovidedbyyou: service.nameprovidedbyyou,
                    priceInstitution: service.priceInstitution,
                    additionalBenefits: "",
                    valuesList: [], // Ensure valuesList is initialized
                }));

                setServices(servicesData);
                setDisplayedServices(servicesData.slice(0, servicesPerPage));
            })
            .catch((error) => {
            });
    }, []);

    useEffect(() => {
        const newDisplayedServices = filteredServices.slice(0, currentPage * servicesPerPage);
        if (JSON.stringify(newDisplayedServices) !== JSON.stringify(displayedServices)) {
            setDisplayedServices(newDisplayedServices);
        }
    }, [filteredServices, currentPage]);

    const handleLoadMore = () => {
        const nextPage = currentPage + 1;
        const newDisplayedServices = services.slice(0, nextPage * servicesPerPage);

        setDisplayedServices(newDisplayedServices);
        setCurrentPage(nextPage);
    };


    const handleInputChange = (id, field, value) => {
        const updatedServices = services.map((service) =>
            service.id === id ? { ...service, [field]: value } : service
        );
        setServices(updatedServices);
    };




    return (
        <div className="dashboard-container">
            <h1>لوحة تحكم مزودي الخدمة</h1>
            <p>
                نرحب بكم في لوحة مزودي الخدمة التي تمنحكم تحكما كاملا في إدارة ملفكم والخدمات التي
                تقدمونها على منصة لها ولك الطبية.
            </p>
            <div className="header-buttons-container">
                <div className="header-buttons">
                    <button
                        onClick={() => setActiveView('display')}
                        style={{
                            marginRight: '10px',
                            backgroundColor: activeView === 'display' ? '#007BFF' : '#f0f0f0',
                            color: activeView === 'display' ? 'white' : 'black',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            cursor: 'pointer',
                        }}
                    >
                        عرض وإضافة المعلومات
                    </button>
                    <button
                        onClick={() => setActiveView('checked')}
                        style={{
                            backgroundColor: activeView === 'checked' ? '#007BFF' : '#f0f0f0',
                            color: activeView === 'checked' ? 'white' : 'black',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            cursor: 'pointer',
                        }}
                    >
                        عرض الطلبات المفحوصة
                    </button>
                </div>
            </div>

            {activeView === 'display' && (
                <div>


                    <div className="repeated-info-form">
                        <h3>معلومات المركز</h3>
                        <label>
                            اسم المركز:
                            <input
                                type="text"
                                placeholder=" مثال: مستشفى السعودي الألماني  (اكتب النوع الاسم) "
                                value={formData.centerName}
                                onChange={(e) => handleChange('centerName', e.target.value)}
                            />
                        </label>


                        <label>
                            المدينة:
                            <input
                                type="text"
                                placeholder="الرياض"
                                value={formData.city}
                                onChange={(e) => handleChange('city', e.target.value)}
                            />
                        </label>
                        <label>
                            الحي:
                            <input
                                type="text"
                                placeholder="حي الحمراء"
                                value={formData.district}
                                onChange={(e) => handleChange('district', e.target.value)}
                            />
                        </label>
                        <label>
                            شعار الخدمة:
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleChange('logo', e.target.files[0])}
                            />
                        </label>
                    </div>


                    <table className="services-table">
                        <thead>
                            <tr>
                                <th colSpan="8">
                                    <div className="filters">
                                        <input
                                            type="text"
                                            className="search-input"
                                            placeholder="ابحث عن الخدمة..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                                        />
                                    </div>
                                </th>

                            </tr>
                            <tr>
                                <th>الرمز التعريفي</th>
                                <th>اسم الخدمة</th>
                                <th>موجز الخدمة</th>
                                <th>الخدمات المشمولة</th>
                                <th>اسم الخدمة المقدم من قبلك</th>
                                <th>سعر البيع الرسمي بالمستشفى</th>
                                <th>سعر البيع في منصة لها ولك</th>
                                <th>قيم مضافة</th>
                            </tr>
                        </thead>

                        <tbody>
                            {displayedServices.map((service) => (
                                <tr key={service.id}>
                                    <td>
                                        <p>  {service.id}</p>
                                        <br />
                                        <a
                                            href={`https://lahalak.sa/DetailsAdd?id=${service.code}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            عرض تفاصيل
                                        </a>
                                    </td>
                                    <td>{service.name}</td>
                                    <td>{service.summery}</td>
                                    <td className="rtl-text">
                                        <strong>{service.includedServices.title}</strong>
                                        <ul>
                                            {service.includedServices.content.map((item, index) => (
                                                <li className="rtl-text" key={index}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>


                                    <td>
                                        <input
                                            type="text"
                                            value={service.nameprovidedbyyou}
                                            onChange={(e) =>
                                                handleInputChange(service.id, 'nameprovidedbyyou', e.target.value)
                                            }
                                        />
                                    </td>



                                    <td>
                                        <input
                                            type="text"
                                            value={service.priceInstitution}
                                            onChange={(e) =>
                                                handleInputChange(service.id, 'priceInstitution', e.target.value)
                                            }
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={service.priceLahaLak}
                                            onChange={(e) =>
                                                handleInputChange(service.id, 'priceLahaLak', e.target.value)
                                            }
                                        />
                                    </td>

                                    <td>
                                        <div>
                                            <h3>إضافة القيم المضافة للمركز:</h3>
                                            <div>
                                                {service.valuesList.map((value, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            marginBottom: '10px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <textarea
                                                            placeholder={`أدخل القيمة المضافة ${index + 1}`}
                                                            value={value}
                                                            onChange={(e) =>
                                                                handleValueChange(service.id, index, e.target.value)
                                                            }
                                                            style={{
                                                                resize: 'none',
                                                                width: '300px',
                                                                height: '60px',
                                                            }}
                                                        />

                                                        <button
                                                            onClick={() => handleRemoveField(service.id, index)}
                                                            style={{
                                                                marginLeft: '10px',
                                                                backgroundColor: '#dc3545',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius: '5px',
                                                                padding: '5px 10px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            حذف
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>

                                            <div style={{ marginTop: '20px' }}>
                                                <button
                                                    onClick={() => handleAddNewField(service.id)}
                                                    style={{
                                                        marginRight: '10px',
                                                        backgroundColor: '#28a745',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    +
                                                </button>

                                                <button
                                                    onClick={() => handleSumilation(service)}
                                                    style={{
                                                        backgroundColor: '#007bff',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    حفظ
                                                </button>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {isModalOpen && (



                        <div className="hospital-card" onClick={closeModal}>
                            <div className="modal-content simulation-modal" onClick={(e) => e.stopPropagation()}>
                                <div className="location-container">

                                    <p> {modalData?.id}</p>
                                    {modalData?.logo &&
                                        <img src={modalData?.logo} alt="Hospital Logo" className="hospital-logo" />}

                                    <div className='column'>
                                        <h1 className="hospital-name">{modalData?.centerName}</h1>
                                        <p className="hospital-subtitle">{modalData?.districadt}</p>
                                    </div>
                                    <div className="locofe">
                                        <img src={locationIcon} alt="Location" className="location-icon" />
                                        <p className="location-text"> {modalData?.location}</p>
                                    </div>
                                </div>

                                <div className="modal-body">
                                    <div className="valueAdded">
                                        <h2 className="whiteC">قيم مضافة</h2>
                                    </div>


                                    <ul className="service-options">
                                        {modalData?.valuesList?.map((value, index) => (
                                            <li key={index}>
                                                <p className="optionsitem">
                                                    <span style={{ fontSize: '34px' }}></span>{value}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="valueAdded">
                                        <h2 className="whiteC">الخيارات المتاحة</h2>
                                    </div>
                                    <div className="service-options">

                                        <div className="samerow">
                                            <p className="optionsitem">
                                                {modalData?.nameprovidedbyyou} - {modalData?.priceLahaLak} &nbsp; ريال سعودي
                                            </p>
                                            <div className="button-container">
                                                <button className="blueTkhile">
                                                    <Link state={{}}>
                                                        <p className="small" style={{ color: 'white' }}>عاين التفاصيل</p>
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>



                                <button
                                    onClick={(event) => {
                                        event.preventDefault(); // Prevent default behavior
                                        handleSubmit(event, modalData?.id, modalData?.logo); // Pass data only if modalData is defined
                                    }}
                                    style={{
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    حفظ
                                </button>




                            </div>
                        </div>
                    )}









                    {displayedServices.length < filteredServices.length && (
                        <button
                            onClick={handleLoadMore}
                            style={{
                                marginTop: '20px',
                                padding: '10px 20px',
                                backgroundColor: '#007BFF',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                        >
                            عرض المزيد
                        </button>
                    )}
                </div>
            )}
            {activeView === 'checked' && <CheckedRequests user={user} />}


        </div>

    );

};

















const CheckedRequests = ({ user }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingId, setEditingId] = useState(null); // To track which item is being edited
    const [editedItem, setEditedItem] = useState(null); // To store the edited values
    const [services, setServices] = useState([]);
    const [displayedServices, setDisplayedServices] = useState([]);
    const servicesPerPage = 50;
    const requestBody = JSON.stringify({ phone_number: user.phone });

    useEffect(() => {
        if (!user?.phone) {
            setError("رقم الهاتف غير متوفر");
            setLoading(false);
            return;
        }




        const fetchData = async () => {
            try {
                const endpoint = process.env.REACT_APP_CHECKED_REQUESTS_ENDPOINT;

                if (!endpoint) {
                    throw new Error("Endpoint is not defined in environment variables.");
                }

                const requestBody = JSON.stringify({ phone_number: user.phone });

                const response = await fetch(endpoint, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: requestBody,
                });


                if (!response.ok) {
                    throw new Error(`Failed to fetch data: ${response.statusText}`);
                }

                const result = await response.json();
                setData(result);
            } catch (err) {
                console.error("Fetch Error:", err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user?.phone]);

    const handleEditClick = (item) => {
        console.log("Item before editing:", item);
        setEditingId(item.id);
        setEditedItem({
            ...item,
            bookingDetails: item.bookingDetails || [], // Ensure it's always initialized
        });
        console.log("Edited Item after initialization:", {
            ...item,
            bookingDetails: item.bookingDetails || [],
        });
    };




    const handleCancelClick = () => {
        setEditingId(null);
        setEditedItem(null);
    };
    useEffect(() => {
        const endpoint = process.env.REACT_APP_PARTNER_SERVICES_URL;

        if (!endpoint) {
            console.error("API Endpoint is not defined.");
            return;
        }

        console.log("API Endpoint:", endpoint);

        fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', // Adjust as needed
            },
        })
            .then((response) => {
                console.log("Response Status:", response.status);
                return response.text(); // Log raw response
            })
            .then((rawData) => {
                console.log("Raw Data:", rawData);
                const data = JSON.parse(rawData);
                console.log("Parsed Data:", data);

                const servicesData = data.map((service) => ({
                    id: service.serviceId,
                    code: service.serviceId,
                    name: service.title,
                    summery: service.summary,
                    includedServices: service.includedServices || { title: "", content: [] },
                    priceLahaLak: service.priceLahaLak,
                    nameprovidedbyyou: service.nameprovidedbyyou,
                    priceInstitution: service.priceInstitution,
                    additionalBenefits: "",
                    valuesList: [],
                }));

                setServices(servicesData);
                setDisplayedServices(servicesData.slice(0, servicesPerPage));
            })
            .catch((error) => {
                console.error("Fetch Error:", error.message);
            });
    }, []);

    const handleSaveClick = async () => {
        if (!editedItem) {
            alert("لا توجد تعديلات للحفظ");
            return;
        }
    
        // Ensure bookingDetails is initialized
        if (!editedItem.bookingDetails) {
            editedItem.bookingDetails = [];
        }
    
        console.log("Booking Details Before Save:", editedItem.bookingDetails);
    
        const confirmed = window.confirm("هل أنت متأكد من أنك تريد حفظ التعديلات؟");
        if (!confirmed) return;
    
        const endpoint = process.env.REACT_APP_UPDATE_REQUEST_ENDPOINT;
        if (!endpoint) {
            console.error("Update endpoint is not defined.");
            return;
        }
    
        try {
            // Prepare the data to be sent, explicitly setting `approved` to null
            const requestData = {
                id: editedItem.id,
                subtitle: editedItem.subtitle?.trim() || "", // Updated subtitle
                price_in_hospital: parseFloat(editedItem.price_in_hospital) || 0, // Ensure the price is a number
                services: JSON.stringify({
                    options: editedItem.options || [],
                    bookingDetails: editedItem.bookingDetails || [],
                }),
                approved: null, // Reset approved status
            };
    
            console.log("Request Data:", requestData); // Debugging log
    
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });
    
            if (!response.ok) {
                throw new Error(`Failed to update the request: ${response.statusText}`);
            }
    
            const result = await response.json();
            console.log("Update successful:", result);
    
            // Update the local data state to reflect the saved changes
            setData((prevData) =>
                prevData.map((item) =>
                    item.id === editedItem.id
                        ? {
                              ...item,
                              ...editedItem,
                              approved: null, // Reset approved to null
                              services: JSON.stringify({
                                  options: editedItem.options || [],
                                  bookingDetails: editedItem.bookingDetails || [],
                              }),
                          }
                        : item
                )
            );
    
            alert("تم تحديث البيانات بنجاح!");
            handleCancelClick(); // Reset editing state
        } catch (error) {
            console.error("Error updating request:", error.message);
            alert("حدث خطأ أثناء حفظ التعديلات. يرجى المحاولة مرة أخرى.");
        }
    };
    



    if (loading) {
        return <p>جاري تحميل البيانات...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }
    console.log("Request Body:", requestBody);
    console.log("Endpoint:", process.env.REACT_APP_CHECKED_REQUESTS_ENDPOINT);


    return (
        <div className="checked_your_request">
            <h2>طلباتك المفحوصة</h2>
            {data.length > 0 ? (
                <div className="checked-requests-container">
                    {/* Display the count of items */}
                    <p className="request-count">
                        <span>
                            عدد الطلبات: <strong>{data.length}</strong>
                        </span> |
                        <span>
                            المقبولة:{" "}
                            <strong>{data.filter((item) => item.approved === 1).length}</strong>
                        </span> |
                        <span>
                            المرفوضة:{" "}
                            <strong>{data.filter((item) => item.approved === 0).length}</strong>
                        </span> |
                        <span>
                            قيد المراجعة:{" "}
                            <strong>
                                {data.filter(
                                    (item) => item.approved === null || item.approved === undefined
                                ).length}
                            </strong>
                        </span>
                    </p>


                    {data.map((item) => {
                        // Parse the JSON from the "services" field
                        let servicesData = {};
                        try {
                            servicesData = JSON.parse(item.services);
                        } catch (error) {
                            console.error("Error parsing services JSON:", error);
                        }
                        const isEditing = editingId === item.id;

                        return (
                            <div key={item.id} className="request-item">


                                <div className="modal-content simulation-modal">

                                    <div className="location-container">
                                        <button
                                            className="edit-icon"
                                            onClick={() => handleEditClick(item)}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px',
                                            }}
                                            title="Edit"
                                        >
                                            <EditIcon fontSize="small" style={{ color: '#007BFF' }} />
                                            <span style={{ color: '#007BFF', fontSize: '14px' }}>Edit</span>
                                        </button>

                                        {item.logo && (
                                            <img
                                                src={item.logo}
                                                alt="شعار المستشفى"
                                                className="hospital-logo"
                                            />
                                        )}
                                        <div className="column">
                                            <h1 className="hospital-name">{item.hospitalName}</h1>
                                            <p className="hospital-subtitle">{item.location}</p>
                                        </div>
                                    </div>

                                    <div className="modal-body">
                                        <div className="valueAdded">
                                            <h2 className="whiteC">الاسم المقدم من قبلي:</h2>
                                            {editingId === item.id ? (
                                                <input
                                                    type="text"
                                                    value={editedItem?.subtitle || ""}
                                                    onChange={(e) =>
                                                        setEditedItem((prev) => ({
                                                            ...prev,
                                                            subtitle: e.target.value,
                                                        }))
                                                    }
                                                    className="editable-input"
                                                />
                                            ) : (
                                                <p className="subtitle-top">{item.subtitle}</p>
                                            )}
                                        </div>

                                        {servicesData.bookingDetails?.length > 0 && (
    <div className="booking-details">
        <h2>القيم مضافة:</h2>
        {editingId === item.id ? (
            <>
                {/* Editing Mode */}
                {editedItem?.bookingDetails?.map((detail, index) => (
                    <div
                        className="booking-detail-item"
                        key={index}
                        style={{ display: "flex", alignItems: "center", gap: "10px" }}
                    >
                        {/* Render all keys starting with 'description' */}
                        {Object.keys(detail).map((key) =>
                            key.startsWith("description") ? (
                                <textarea
                                    key={key}
                                    value={detail[key] || ""}
                                    onChange={(e) => {
                                        const updatedBookingDetails = [...editedItem.bookingDetails];
                                        updatedBookingDetails[index][key] = e.target.value; // Update specific key
                                        setEditedItem((prev) => ({
                                            ...prev,
                                            bookingDetails: updatedBookingDetails,
                                        }));
                                    }}
                                    placeholder={`أدخل ${key}`}
                                    className="editable-textarea"
                                />
                            ) : null
                        )}
                        {/* Delete Button */}
                        <button
                            className="delete-description-btn"
                            onClick={() => {
                                const updatedBookingDetails = [...editedItem.bookingDetails];
                                updatedBookingDetails.splice(index, 1); // Remove the specific entry
                                setEditedItem((prev) => ({
                                    ...prev,
                                    bookingDetails: updatedBookingDetails,
                                }));
                            }}
                        >
                            حذف
                        </button>
                    </div>
                ))}
                {/* Add New Entry */}
                <button
                    className="add-new-description-btn"
                    onClick={() => {
                        const updatedBookingDetails = [...(editedItem?.bookingDetails || [])];
                        const newDetail = { description1: "" }; // Default structure for new entries
                        updatedBookingDetails.push(newDetail);
                        setEditedItem((prev) => ({
                            ...prev,
                            bookingDetails: updatedBookingDetails,
                        }));
                    }}
                >
                    + أضف وصف جديد
                </button>
            </>
        ) : (
            /* Viewing Mode */
            servicesData.bookingDetails.map((detail, index) => (
                <div className="booking-detail-item" key={index}>
                    {Object.keys(detail).map((key) =>
                        key.startsWith("description") ? (
                            <p key={key}>{detail[key]}</p>
                        ) : null
                    )}
                </div>
            ))
        )}
    </div>
)}







                                        {servicesData.options?.length > 0 && (
                                            <div className="valueAdded">
                                                <h2 className="whiteC">السعر المرفوع على لها لك:</h2>
                                                {editingId === item.id ? (
                                                    servicesData.options.map((option, index) => (
                                                        <div key={index} className="editable-option">
                                                            <input
                                                                type="number"
                                                                value={editedItem?.options?.[index]?.price || option.price || ""}
                                                                onChange={(e) => {
                                                                    const updatedOptions = [...(editedItem?.options || servicesData.options)];
                                                                    updatedOptions[index] = {
                                                                        ...updatedOptions[index],
                                                                        price: e.target.value,
                                                                    };
                                                                    setEditedItem((prev) => ({
                                                                        ...prev,
                                                                        options: updatedOptions,
                                                                    }));
                                                                }}
                                                                className="editable-field"
                                                            />
                                                            <span className="currency">ريال</span>
                                                        </div>
                                                    ))
                                                ) : (
                                                    servicesData.options.map((option, index) => (
                                                        <p className="subtitle-top" key={index}>
                                                            {option.price} ريال
                                                        </p>
                                                    ))
                                                )}
                                            </div>
                                        )}


                                        {/* Hospital Price (السعر بالمستشفى) */}
                                        <div className="valueAdded">
                                            <h2 className="whiteC">السعر بالمستشفى:</h2>
                                            {editingId === item.id ? (
                                                <input
                                                    type="number"
                                                    value={editedItem?.price_in_hospital || ""}
                                                    onChange={(e) =>
                                                        setEditedItem((prev) => ({
                                                            ...prev,
                                                            price_in_hospital: e.target.value,
                                                        }))
                                                    }
                                                    className="editable-input"
                                                />
                                            ) : (
                                                <p className="subtitle-top">{item.price_in_hospital} ريال</p>
                                            )}
                                        </div>

                                        <div className="valueAdded">
                                            <h2 className="whiteC">تاريخ الإنشاء:</h2>
                                            <p className="subtitle-top">
                                                {new Date(item.created_at).toLocaleDateString()}
                                            </p>
                                        </div>

                                        <div className="valueAdded">
                                            <h2 className="whiteC">حالة الطلب:</h2>
                                            <p
                                                className="subtitle-top"
                                                style={{
                                                    color:
                                                        item.approved === 1
                                                            ? "green"
                                                            : item.approved === 0
                                                                ? "red"
                                                                : "orange",
                                                }}
                                            >
                                                {item.approved === 1
                                                    ? "مقبول"
                                                    : item.approved === 0
                                                        ? "مرفوض"
                                                        : "قيد المراجعة"}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Save/Cancel Buttons */}
                                {editingId === item.id && (
                                    <div className="edit-actions">
                                        <button
                                            className="save-btn"
                                            onClick={handleSaveClick}
                                        >
                                            حفظ
                                        </button>
                                        <button
                                            className="cancel-btn"
                                            onClick={handleCancelClick}
                                        >
                                            إلغاء
                                        </button>
                                    </div>
                                )}

                                {/* Rejection Reason Message */}
                                {item.approved === 0 && item.rejection_reason && (
                                    <div className="rejection-message">
                                        <h2>سبب الرفض</h2>
                                        <p>{item.rejection_reason}</p>
                                    </div>
                                )}
                            </div>
                        );

                    })}
                </div>
            ) : (
                <p className="no-requests-message">لا توجد طلبات مفحوصة.</p>
            )}






        </div>
    );
};



export default Our_Partners_Services;











