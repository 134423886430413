import React, { useState, useEffect } from "react";
import "./ProviderManagement.css";
import { Link } from 'react-router-dom';

const ProviderManagement = () => {
    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterLocation, setFilterLocation] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [activeView, setActiveView] = useState("current"); // Tracks the active view
    const openModal = (provider) => {
        setSelectedProvider(provider);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedProvider(null);
        setIsModalOpen(false);
    };
    const handleAction = async (id, status, reason = "") => {
        console.log("Initiating update request:", { id, status, reason }); // Debugging log for payload

        try {
            const response = await fetch(process.env.REACT_APP_PROVIDER_MANAGEMENT_ENDPOINT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id, status, reason }),
            });

            // Parse the JSON response
            const result = await response.json();
            console.log("API Response:", result); // Log the API response for debugging

            // Check if the response is successful
            if (response.ok) {
                console.log("Update Successful:", result);

                // Update the local state to reflect the new status
                setProviders((prev) =>
                    prev.map((provider) =>
                        provider.id === id
                            ? { ...provider, approved: status, rejection_reason: reason || null }
                            : provider
                    )
                );

                alert(`Provider status updated successfully!`);
            } else {
                console.error("Error updating provider:", result.error);
                alert(`Failed to update provider: ${result.error}`);
            }
        } catch (error) {
            console.error("Request failed:", error.message);

            alert(
                "An unexpected error occurred while updating the provider. Please check your connection and try again."
            );
        }
    };


    useEffect(() => {
        const fetchProviders = async () => {
            try {
                const endpoint = process.env.REACT_APP_PROVIDER_MANAGEMENT_ENDPOINT;

                if (!endpoint) {
                    throw new Error("Endpoint is not defined in environment variables.");
                }

                const response = await fetch(endpoint);

                if (!response.ok) {
                    throw new Error(`Failed to fetch providers: ${response.statusText}`);
                }

                const data = await response.json();
                console.log("Fetched Providers Data:", data); // Log the data to ensure it's structured as expected
                setProviders(data);
            } catch (err) {
                console.error("Fetch Error:", err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProviders();
    }, []);

    const filteredProviders = providers.filter((provider) => {
        const matchesSearch = provider.hospitalName && provider.hospitalName.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesStatus = filterStatus ? provider.status === filterStatus : true;
        const matchesLocation = filterLocation ? provider.location === filterLocation : true;
        return matchesSearch && matchesStatus && matchesLocation;
    });

    const approvedCount = providers.filter((provider) => provider.status === "approved").length;
    const rejectedCount = providers.filter((provider) => provider.status === "rejected").length;

    if (loading) return <p>Loading providers...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="provider-management">
            {/* Header Section */}
            <div className="header-section">
                <h1>إدارة مقدمي الخدمة</h1>
                <p className="page-summary">
                    قم بإدارة مقدمي الخدمة بما في ذلك مراجعة الطلبات والموافقة عليها أو رفضها.
                </p>
                <div className="actions">
                    <button
                        className="primary-btn"
                        onClick={() => setActiveView("current")}
                    >
                        إضافة مقدم خدمة
                    </button>
                    <button
                        className="secondary-btn"
                        onClick={() => setActiveView("child")}
                    >
                        معلومات مزود الخدمه
                    </button>
                </div>
            </div>
            <div className="content-section">
                {activeView === "child" && <Contract />}
                {activeView === "current" && (
                    <div>
                        <div className="filter-section">
                            <input
                                type="text"
                                placeholder="ابحث عن مقدم الخدمة..."
                                className="search-bar"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <select
                                className="dropdown"
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                            >
                                <option value="">كل الحالات</option>
                                <option value="approved">مقبولة</option>
                                <option value="rejected">مرفوضة</option>
                                <option value="pending">قيد المراجعة</option>
                            </select>
                            <select
                                className="dropdown"
                                value={filterLocation}
                                onChange={(e) => setFilterLocation(e.target.value)}
                            >
                                <option value="">كل المواقع</option>
                                <option value="Riyadh">الرياض</option>
                                <option value="Jeddah">جدة</option>
                                <option value="Dammam">الدمام</option>
                            </select>
                        </div>

                        {/* Statistics Summary */}
                        <div className="stats-summary">
                            <p>
                                عدد مقدمي الخدمة: <strong>{providers.length}</strong>
                            </p>
                            <p>
                                المقبولة:{" "}
                                <strong>
                                    {providers.filter((provider) => provider.approved === 1).length}
                                </strong>
                            </p>
                            <p>
                                المرفوضة:{" "}
                                <strong>
                                    {providers.filter((provider) => provider.approved === 0).length}
                                </strong>
                            </p>
                            <p>
                                قيد المراجعة:{" "}
                                <strong>
                                    {providers.filter(
                                        (provider) => provider.approved === null || provider.approved === undefined
                                    ).length}
                                </strong>
                            </p>
                        </div>


                        {/* Providers Table */}
                        <table className="provider-table">
                            <thead>
                                <tr>
                                    <th>اسم مقدم الخدمة</th>
                                    <th>الموقع</th>
                                    <th>الحي</th>
                                    <th>الاسم المسمى من قبل مزود الخدمة</th>
                                    <th>القيمه المضافه</th>
                                    <th>السعر المقدم لنا</th>
                                    <th>الرموز التعريفية للخدمة</th>
                                    <th>السعر بالمستشفى</th>
                                    <th>رقم الهاتف</th>
                                    <th>الحالة</th>
                                    <th>سبب الرفض</th>
                                    <th>الشعار</th>
                                    <th>تاريخ الإضافة</th>
                                    <th>الإجراءات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProviders.length > 0 ? (
                                    filteredProviders.map((provider) => {
                                        let servicesData = {};
                                        try {
                                            servicesData = JSON.parse(provider.services || "{}");
                                        } catch (error) {
                                            console.error("Error parsing services JSON:", error);
                                        }

                                        const optionName =
                                            servicesData.options?.[0]?.optionName || "غير متوفر";
                                        const price =
                                            servicesData.options?.[0]?.price
                                                ? `${servicesData.options[0].price} ريال`
                                                : "غير متوفر";
                                        const addedValue =
                                            servicesData.bookingDetails?.[0]?.description1 || "غير متوفر";

                                        return (
                                            <tr key={provider.id}>
                                                {/* Hospital Name */}
                                                <td>{provider.hospitalName || "غير متوفر"}</td>

                                                {/* Location */}
                                                <td>{provider.location || "غير متوفر"}</td>

                                                {/* Sub-Location */}
                                                <td>{provider.subtitle || "غير متوفر"}</td>

                                                {/* Option Name */}
                                                <td>{optionName}</td>

                                                {/* Added Value */}
                                                <td>{addedValue}</td>

                                                {/* Price Offered */}
                                                <td>{price}</td>

                                                {/* Service IDs */}
                                                <td>
                                                    <a
                                                        href={`https://lahalak.sa/DetailsAdd?id=${provider.serviceIds}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {provider.serviceIds}
                                                    </a>
                                                </td>

                                                {/* Hospital Price */}
                                                <td>
                                                    {provider.price_in_hospital
                                                        ? `${provider.price_in_hospital} ريال`
                                                        : "غير متوفر"}
                                                </td>

                                                {/* Phone Number */}
                                                <td>{provider.phone_number || "غير متوفر"}</td>

                                                {/* Status */}
                                                <td>
                                                    <span
                                                        className={`status ${provider.approved === 1
                                                            ? "approved"
                                                            : provider.approved === 0
                                                                ? "rejected"
                                                                : "pending"
                                                            }`}
                                                    >
                                                        {provider.approved === 1
                                                            ? "مقبولة"
                                                            : provider.approved === 0
                                                                ? "مرفوضة"
                                                                : "معلقة"}
                                                    </span>
                                                </td>

                                                {/* Rejection Reason */}
                                                <td>
                                                    {provider.rejection_reason ||
                                                        (provider.approved === 1 ? "لا يوجد" : "غير متوفر")}
                                                </td>

                                                {/* Logo */}
                                                <td>
                                                    {provider.logo ? (
                                                        <img
                                                            src={provider.logo}
                                                            alt="شعار مقدم الخدمة"
                                                            style={{ width: "50px", height: "50px" }}
                                                        />
                                                    ) : (
                                                        "غير متوفر"
                                                    )}
                                                </td>

                                                {/* Created At */}
                                                <td>
                                                    {provider.created_at
                                                        ? new Date(provider.created_at).toLocaleDateString()
                                                        : "غير متوفر"}
                                                </td>

                                                {/* Actions */}
                                                <td>
                                                    <button
                                                        className="action-btn view"
                                                        onClick={() => openModal(provider)}
                                                    >
                                                        عرض
                                                    </button>
                                                    <button
                                                        className="action-btn approve"
                                                        onClick={() => handleAction(provider.id, 1)} // Approve
                                                    >
                                                        قبول
                                                    </button>
                                                    <button
                                                        className="action-btn reject"
                                                        onClick={() => {
                                                            const reason = prompt("Enter rejection reason (optional):");
                                                            handleAction(provider.id, 0, reason); // Reject with reason
                                                        }}
                                                    >
                                                        رفض
                                                    </button>
                                                    <button
                                                        className="action-btn pending"
                                                        onClick={() => handleAction(provider.id, null)} // Pending
                                                    >
                                                        معلق
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="14">لا توجد نتائج مطابقة.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>


                        {isModalOpen && (
                            <div className="hospital-card" onClick={closeModal}>
                                <div
                                    className="modal-content simulation-modal"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {/* Location Container */}
                                    <div className="location-container">
                                        <p>المعرف: {selectedProvider?.id}</p>
                                        {selectedProvider?.logo && (
                                            <img
                                                src={selectedProvider?.logo}
                                                alt="Hospital Logo"
                                                className="hospital-logo"
                                            />
                                        )}

                                        <div className="column">
                                            <h1 className="hospital-name">{selectedProvider?.hospitalName}</h1>
                                            <p className="hospital-subtitle">{selectedProvider?.location}</p>
                                        </div>

                                        <div className="locofe">
                                            <p className="location-text">
                                                <strong>الموقع:</strong> {selectedProvider?.location}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Modal Body */}
                                    <div className="modal-body">
                                        {/* Booking Details Section */}
                                        <div className="valueAdded">
                                            <h2 className="whiteC">قيم مضافة</h2>
                                        </div>
                                        <ul className="service-options">
                                            {(() => {
                                                let bookingDetails = [];
                                                try {
                                                    const servicesData = JSON.parse(selectedProvider?.services || "{}");
                                                    bookingDetails = servicesData.bookingDetails || [];
                                                } catch (error) {
                                                    console.error("Error parsing services JSON in modal:", error);
                                                }

                                                return bookingDetails.length > 0 ? (
                                                    bookingDetails.map((detail, index) => (
                                                        <li key={index}>
                                                            {Object.keys(detail).map((key, i) => (
                                                                key.startsWith("description") && detail[key] ? ( // Check if the key starts with "description" and has a value
                                                                    <p className="optionsitem" key={i}>
                                                                        {detail[key]}
                                                                    </p>
                                                                ) : null
                                                            ))}
                                                        </li>

                                                    ))
                                                ) : (
                                                    <p className="optionsitem">لا توجد تفاصيل الحجز المتاحة</p>
                                                );
                                            })()}
                                        </ul>

                                        {/* Options Section */}
                                        <div className="valueAdded">
                                            <h2 className="whiteC">الخيارات المتاحة</h2>
                                        </div>
                                        <div className="service-options">
                                            {(() => {
                                                let options = [];
                                                try {
                                                    const servicesData = JSON.parse(selectedProvider?.services || "{}");
                                                    options = servicesData.options || [];
                                                } catch (error) {
                                                    console.error("Error parsing services JSON in modal:", error);
                                                }

                                                return options.length > 0 ? (
                                                    options.map((option, index) => (
                                                        <div className="samerow" key={index}>
                                                            <p className="optionsitem">
                                                                {option.optionName} - {option.price} ريال سعودي
                                                            </p>
                                                            <div className="button-container">
                                                                <button className="blueTkhile">
                                                                    <p className="small" style={{ color: "white" }}>
                                                                        عاين التفاصيل
                                                                    </p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p className="optionsitem">لا توجد خيارات متاحة</p>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                )}

            </div>

        </div>
    );
};







const Contract = () => {
    const [hospitals, setHospitals] = useState([

    ]);
    const [loading, setLoading] = useState(false);

    const [newHospital, setNewHospital] = useState({
        id: "",
        name: "",
        contract: "",
        contractFile: null, // Added to hold the selected file
        note: "",
        startDate: "",
        endDate: "",
        name_relation: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewHospital({ ...newHospital, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewHospital({ ...newHospital, contractFile: file });
        }
    };
    const fetchHospitals = async () => {
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_FETCH_HOSPITALS_URL, {
                method: "GET",
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error("Unexpected server response:", errorText);
                throw new Error("خطأ أثناء جلب البيانات. الرجاء المحاولة مرة أخرى.");
            }

            const result = await response.json();

            if (result.status === "success") {
                setHospitals(result.hospitals); // Set the fetched hospitals
            } else {
                alert(result.message);
            }
        } catch (error) {
            console.error("Error fetching hospitals:", error);
            alert("خطأ أثناء جلب البيانات: " + error.message);
        } finally {
            setLoading(false); // Hide loading indicator
        }
    };

    useEffect(() => {
        fetchHospitals();
    }, []);
    const addRow = async () => {
        if (
            newHospital.name &&
            newHospital.id &&
            newHospital.contractFile && // Ensure a file is selected
            newHospital.note &&
            newHospital.startDate &&
            newHospital.endDate &&
            newHospital.name_relation
        ) {
            try {
                // Set loading state to true
                setLoading(true);
    
                // Create a FormData object to send form data and file
                const formData = new FormData();
                formData.append("id", newHospital.id);
                formData.append("name", newHospital.name);
                formData.append("contract", newHospital.contractFile); // Attach the file
                formData.append("note", newHospital.note);
                formData.append("startDate", newHospital.startDate);
                formData.append("endDate", newHospital.endDate);
                formData.append("name_relation", newHospital.name_relation);
    
                const response = await fetch(process.env.REACT_APP_ADD_HOSPITAL_URL, {
                    method: "POST",
                    body: formData, // Send FormData directly (no `Content-Type` header needed)
                });
    
                // Check if the response is OK
                if (!response.ok) {
                    const errorText = await response.text(); // Get the raw response text
                    console.error("Unexpected server response:", errorText);
                    throw new Error("خطأ أثناء الاتصال بالخادم. الرجاء المحاولة مرة أخرى.");
                }
    
                // Parse the JSON response
                const result = await response.json();
    
                // Check the status of the result
                if (result.status === "success") {
                    // Add the new hospital row with the returned contract URL
                    setHospitals((prevHospitals) => [
                        ...prevHospitals,
                        { ...newHospital, contract: result.contractUrl },
                    ]);
    
                    // Reset form fields
                    setNewHospital({
                        id: "",
                        name: "",
                        contractFile: null, // Reset file input
                        note: "",
                        startDate: "",
                        endDate: "",
                        name_relation: "",
                    });
    
                    alert("تم إضافة المستشفى بنجاح");
                } else {
                    if (result.message.includes("Duplicate entry")) {
                        alert("المستشفى بهذا المعرف موجود مسبقًا.");
                    } else {
                        alert(result.message);
                    }
                }
            } catch (error) {
                console.error("Error saving hospital:", error);
                alert("خطأ أثناء حفظ المستشفى: " + error.message);
            } finally {
                // Set loading state to false
                setLoading(false);
            }
        } else {
            // Alert if any required fields are missing
            alert("يرجى ملء جميع الحقول قبل الإضافة");
        }
    };
    






    const deleteRow = async (indexToDelete) => {
        const hospitalToDelete = hospitals[indexToDelete];
        try {
            const response = await fetch(process.env.REACT_APP_DELETE_HOSPITAL_URL, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: hospitalToDelete.id }),
            });

            const result = await response.json();
            if (result.status === 'success') {
                setHospitals(hospitals.filter((_, index) => index !== indexToDelete)); // Update table
            } else {
                alert(result.message);
            }
        } catch (error) {
            alert('Error deleting hospital: ' + error.message);
        }
    };



    return (
        <div style={{ padding: "20px" }}>
            <h2>عقود المستشفيات</h2>
            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                <thead>
                    <tr>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>اسم المستشفى</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>رقم الهاتف</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>العقد</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>ملاحظة</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>تاريخ البداية</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>تاريخ النهاية</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>مسؤول العلاقات</th>
                        <th style={{ border: "1px solid #ddd", padding: "8px" }}>حذف</th>
                    </tr>
                </thead>
                <tbody>
                    {hospitals.map((hospital, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{hospital.name}</td>
                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{hospital.id}</td>
                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                                <a href={hospital.contract} target="_blank" rel="noopener noreferrer">
                                    عرض العقد
                                </a>
                            </td>
                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{hospital.note}</td>
                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{hospital.startDate}</td>
                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{hospital.endDate}</td>
                            <td style={{ border: "1px solid #ddd", padding: "8px" }}>{hospital.name_relation}</td>
                            <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                <button
                                    onClick={() => deleteRow(index)}
                                    style={{
                                        padding: "5px 10px",
                                        backgroundColor: "red",
                                        color: "white",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    حذف
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ marginTop: "20px" }}>
                <h3>إضافة مستشفى جديد</h3>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <input
                        type="text"
                        name="name"
                        placeholder="اسم المستشفى"
                        value={newHospital.name}
                        onChange={handleInputChange}
                        style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                    <input
                        type="text"
                        name="id"
                        placeholder="رقم الهاتف"
                        value={newHospital.id}
                        onChange={handleInputChange}
                        style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                    <input
                        type="file"
                        name="contract"
                        onChange={handleFileChange}
                        style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                    <input
                        type="text"
                        name="note"
                        placeholder="ملاحظة"
                        value={newHospital.note}
                        onChange={handleInputChange}
                        style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                    <label style={{ display: "block", marginBottom: "5px" }}>تاريخ البداية</label>
                    <input
                        type="date"
                        name="startDate"
                        value={newHospital.startDate}
                        onChange={handleInputChange}
                        style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc", marginBottom: "10px" }}
                    />
                    <label style={{ display: "block", marginBottom: "5px" }}>تاريخ النهاية</label>
                    <input
                        type="date"
                        name="endDate"
                        value={newHospital.endDate}
                        onChange={handleInputChange}
                        style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />

                    <input
                        type="text"
                        name="name_relation"
                        placeholder="مسؤول العلاقات"
                        value={newHospital.name_relation}
                        onChange={handleInputChange}
                        style={{ padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                    <button
                        onClick={addRow}
                        className="add-row-button"
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "green",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                        }}
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? (
                            <span
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    border: "2px solid white",
                                    borderTop: "2px solid transparent",
                                    borderRadius: "50%",
                                    animation: "spin 1s linear infinite",
                                }}
                            ></span>
                        ) : (
                            "إضافة"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};









export default ProviderManagement;
